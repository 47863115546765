<template>
  <div class="home">
    <header-menu v-show="headerState" />
    <router-view :class="headerState?'data_cont_box_router':''" />
    <foot-menu v-if="footerState" />
  </div>
</template>
<script>
import headerMenu from "./components/common/header_menu.vue";
import footMenu from "./components/common/foot_menu.vue";

export default {
  name: "pc-home",
  components: {
    headerMenu,
    footMenu,
  },
  data() {
    return {
      headerState: true,
      footerState: true,
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        const { headerIsShow, footerIsShow } = newVal.meta;
        this.headerState = headerIsShow ? false : true;
        this.footerState = footerIsShow ? false : true;
         document.body.scrollTop = 0;
         document.documentElement.scrollTop = 0;
        
      },
      immediate: true,
    },
  },

  mounted() {
    
    this.getWebsiteInfo(); //获取网站配置信息
  },
  methods: {
    getWebsiteInfo() {
      //获取网站配置信息
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
      });
    },
  },
};
</script>

<style lang="scss">
.data_cont_box_router{
  margin-top: 80px;
}
html,boyd {
  font-size: 14px ;
}
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
.none {
  display: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}
.overflow_y {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}

</style>
