<template>
  <div class="login">
    <div class="close" @click="closeBtn">+</div>
    <img class="logo" src="~@/assets/m/logo.png">
    <div class="input-box type1">
      <input type="number" v-model="logInfo.username" class="input" placeholder="请输入手机号">
      <div class="clear" v-if="logInfo.username!=''" @click="logInfo.username=''">+</div>
    </div>
    <div class="input-box type1 password">
      <input :type="openEyes?'text':'password'" v-model="logInfo.password" class="input" placeholder="请输入密码">
      <div class="eyes" :class="{'open': openEyes}" @click="openEyes=!openEyes"></div>
    </div>
    <div class="opes">
      <span class="ope register" @click="jumpPage('/register_info?showNum=1')">注册账号</span>
      <span class="ope Forgot-pass" @click="jumpPage('/retrievePassword')">忘记密码</span>
    </div>
    <label class="privacy-policy">
      <div class="checkbox" :class="{'checked': logInfo.agree}"><input class="checkbox" type="checkbox" v-model="logInfo.agree"></div>
      登录即代表同意<span class="agreement" @click.stop="toAgreement">《用户协议》</span>和<span class="agreement" @click.stop="toAgreement">《隐私政策》</span>
    </label>
    <button class="sub-btn" :class="{'disable': btnDisable}" @click="logBtn">登录</button>
    <!-- <div class="wx-login" v-if="isWeiXin" @click="wxLogin">微信登录<img class="wx-logo" src="~@/assets/m/wx-logo.png"></div> -->
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { IsRegular, IsWeiXin } from '@/utils/util'
export default {
  name: 'm-login',
  data(){
    return {
      logInfo: {        //登录信息
        username: '',     //手机号
        password: '',     //密码
        type: 1,          //1.个人登录 2.企业登录
        agree: true,      //同意隐私条款
      },
      openEyes: false,  //文本框眼睛
      btnDisable: true, //按钮禁用
      isWeiXin: false,  //是否在微信
    }
  },
  computed: {
    
  },
  mounted(){
    this.isWeiXin = IsWeiXin();
  },
  methods:{
    ...mapMutations(['CloseMLoginModal', 'ShowTipModal', 'ClearUserInfo']), //登录弹窗
    logBtn(){
      if(!this.btnDisable){
        if(!IsRegular(this.logInfo.username)){
          this.ShowTipModal({
            text: '请填写正确的手机号',     //提示弹窗内容
          })
          return;
        }

        this.$axios.post(`/v1/login/login`, this.logInfo).then(res => {
          if(res.code == 0){
            localStorage.setItem('auth', JSON.stringify(res.data.auth));
            localStorage.setItem('user', JSON.stringify(res.data.user));
            localStorage.setItem('userInfo', JSON.stringify(res.data.user));
            localStorage.setItem('logoDate', Date.parse(new Date())/1000);

            this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true}).then(() => {  //登录记录
              location.reload(); //刷新页面
              this.CloseMLoginModal();
            })
          }else{
            this.ShowTipModal({
              text: res.msg,     //提示弹窗内容
            })
          }
        })
      }
    },
    //关闭登录
    closeBtn(){
      let url = this.$route.fullPath;
      
      if(url.includes('userCenter') || url.includes('learnCenter')){
        this.logInfo.username = '';
        this.logInfo.password = '';
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('logoDate');
        
        this.ClearUserInfo();
        this.$router.push('/index');
        this.CloseMLoginModal();
      }else{
        this.CloseMLoginModal();
      }
    },
    //跳转页面
    jumpPage(url){
      this.CloseMLoginModal();
      this.$router.push(url);
    },
    //微信登录
    wxLogin(){
      this.$axios.post(`/v1/px/login/wxCode`, {
        source: 1,
        return_url: window.location.href.split('/#')[0],
      }).then(res => {
        window.location.href = res.data.url;
      })
    },
    toAgreement(){
      this.CloseMLoginModal();
      this.$router.push('/agreement');
    }
  },
  watch: {
    //监听表单
    logInfo: {
      handler(newName){
        if(newName.username=='' || newName.password=='' || !newName.agree){
          this.btnDisable = true;
        }else{
          this.btnDisable = false;
        }
      },
      immediate: true,
      deep:true //为true，表示深度监听，这时候就能监测到a值变化
    },
  }
}
</script>

<style lang="scss" scoped>
.login{
  width: 100%;
  height: 100vh;
  padding: 0 18px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.close{
  width: 46px;
  height: 46px;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  margin-left: -18px;
}
.logo{
  display: block;
  width: 160px;
  height: auto;
  margin: 55px auto 85px;
}
.input-box.type1{
  margin-bottom: 25px;
}
.opes{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -5px;
  .ope{
    font-size: 13px;
    color: #999;
  }
}
.privacy-policy{
  margin-top: 35px;
  .agreement{
    color: #0065DF;
  }
}
.wx-login{
  width: 100px;
  display: flex;
  align-items: center;
  margin: 40px auto;
  .wx-logo{
    width: 22px;
    margin-left: 10px;
  }
}
</style>