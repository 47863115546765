<template>
  <div class="footer" ref="foot">
    <div class="box_footer">
      <div class="foot_list" v-if="footLogo">
        <div class="first_menu flex">
          <div class="right_img">
            <ul class="flex">
              <!-- <li><img src="@/assets/pc/deep_code1.jpg" alt="" /></li>
              <li><img src="@/assets/pc/deep_code3.jpg" alt="" /></li> -->
              <!-- <li><img :src="siteInfo.site_icon" alt="" /></li>
              <li><img :src="siteInfo.site_logo" alt="" /></li> -->
              <li>
                <p class="deep_logo">
                  <img :src="siteInfo.footer_logo" alt="" srcset="" />
                </p>
                <p class="phone_num">{{ siteInfo.phone }}</p>
                <!-- <p class="cursor btn">立即咨询</p> -->
              </li>
            </ul>
          </div>
          <div class="menu flex">
            <ul>
              <li class="tips">解决方案</li>
              <li>
                <a
                  href="https://zgyxk.kskstudy.com/researchList"
                  target="_blank"
                  rel="noopener noreferrer"
                  >企业研学</a
                >
              </li>
              <li><a href="http://npx.kskstudy.com/teacherList" target="_blank" rel="noopener noreferrer">大师来了</a> </li>
              <li><a href="" rel="noopener noreferrer">企业VIP</a> </li>
            </ul>
            <ul>
              <li class="tips">合作方式</li>
              <li>
                <a
                  href="http://landing.kjcxchina.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  >讲师入驻</a
                >
              </li>
              <!-- <li>
                <a href="http://npx.kskstudy.com/register_info?showNum=2" target="_blank" rel="noopener noreferrer"
                  >内容合作</a
                >
              </li> -->
              <li @click="maskState=true">
               内容合作
              </li>
              <li @click="maskState=true">商务合作</li>
            </ul>
            <ul>
              <li class="tips">其他平台</li>
              <li>
                <a
                  href="http://open.kskstudy.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  >开放平台</a
                >
              </li>
              <li>
                <a
                  href="http://www.kskstudy.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  >KSK职业技能培训平台</a
                >
              </li>
              <li>
                <a
                  href="http://kjcxchina.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  >中工科创</a
                >
              </li>
            </ul>
            <ul>
              <li class="tips">关于我们</li>
              <li>法律声明</li>
              <li>投诉建议</li>
              <li>隐私条款</li>
            </ul>
          </div>

          <div class="code_img_list">
            <ul class="flex">
              <li>
                <p class="img"><img src="@/assets/pc/newIndex/code_img1.png" alt=""></p>
                <p class="text">微信联系</p>
              </li>
              <li>
                <p class="img"><img src="@/assets/pc/newIndex/code_img2.png" alt=""></p>
                <p class="text">小程序</p>
              </li>
              <li>
                <p class="img"><img src="@/assets/pc/newIndex/code_img3.png" alt=""></p>
                <p class="text">关注微信公众号</p>
              </li>
            </ul>
          </div>
          
        </div>
        <div class="sec_menu flex">
          <p v-if="blogroll.length > 0">友情链接</p>
          <ul class="flex">
            <li v-for="item in blogroll" :key="item.id">
              <a :href="item.link" target="_black">{{ item.title }}</a>
            </li>
            <!-- <li>慧聪机电网</li>
            <li>中联重科</li>
            <li>三一重工</li>
            <li>中车集团</li>
            <li>中华全国总工会</li>
            <li>慧聪机电网</li>
            <li>中联重科</li>
            <li>中联重科</li>
            <li>中车集团</li>
            <li>中车集团</li>
            <li>中车集团</li>
            <li>中车集团</li>
            <li>中车集团</li>
            <li>中车集团</li>
            <li>中车集团</li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="deep_code">
      <div class="deep_text">
        <p>
          Copyright © 2015-2023 kskstudy.com All Rights Reserved 京ICP备{{
            siteInfo.beianhao
          }}
        </p>
      </div>
    </div>
     <!-- 弹出框 -->
    <div class="mask_show_box fixed_bgm" v-if="maskState">
     
      <div class="contBox">
         <p class="close" @click="maskState=false">×</p>
        <ul>
          <img src="@/assets/code_img_btn.png" alt="">
          <!-- <li class="show_more">
            <p class="text">成为代理</p>
            <div class="show_text">
              <div class="phone_list flex">
                <div class="left_text">
                  <div class="num">
                    <span>客服电话：</span>400-0123-823&nbsp;<span></span>
                  </div>
                </div>
                <div class="code_img">
                  <img src="@/assets/pc/right_code.jpg" alt="" />
                </div>
              </div>
            </div>
          </li> -->
          <!-- <p class="goHeader cursor" @click="goTop">回到顶部</p> -->
        </ul>
      </div>
    </div>
    <Tip
      :text="tipModal.text"
      :showBtns="tipModal.showBtns"
      v-if="tipModal.show"
      :fun="tipModal.fun"
      :confirmFun="tipModal.confirmFun"
    />
    <login-way v-if="loginModal.show"></login-way>
    <!-- 右侧固定导航 -->
    <div
      v-if="fixedState"
      class="fixed_menu"
      :class="goTopShow ? 'goTop' : 'fixed_menu'"
    >

      <ul class="cont_ul">
        <li class="code_li">
          <p class="icon"><img src="@/assets/pc/newIndex/right_icon1.png" alt=""></p>
          <p class="text">扫码咨询</p>
          <div class="show_cont_info code_info">
            <p class="tips">扫码加微信咨询</p>
            <p class="img"><img src="@/assets/pc/right_code.jpg" alt=""></p>
          </div>
        </li>
        <li class="phone_li">
          <p class="icon"><img src="@/assets/pc/newIndex/right_icon2.png" alt=""></p>
          <p class="text">热线电话</p>
          <div class="show_cont_info phone_cont">
            <p class="phone">400-0123-823</p>
          </div>
        </li>
        <a href="https://www.wjx.cn/vm/OXIWqro.aspx#" target="_blank" rel="noopener noreferrer">
          <li>
            <p class="icon"><img src="@/assets/pc/newIndex/right_icon3.png" alt=""></p>
            <p class="text">免费试用</p>
          </li>
        </a>
        
        <li @click="goTop">
          <p class="icon"><img src="@/assets/pc/newIndex/right_icon4.png" alt=""></p>
          <p class="text">顶部</p>
        </li>
      </ul>
      <!-- <ul>
       
        <li class="show_more" @click="$router.push('/company_vip')">
         
          <p class="text">咨询服务</p>
          <div class="show_text">
            <div class="title_icon">
              <span>咨询服务</span>
            </div>
            <div class="phone_list flex">
              <div class="left_text">
                <div class="num"><span>培训咨询：</span>400-0123-823&nbsp;<span></span></div>
                <div class="num"><span>商务合作：</span>17610066752&nbsp;<span></span></div>
              </div>
              <div class="code_img">
                <img src="@/assets/pc/right_code.jpg" alt="" />
              </div>
            </div>
          </div>
        </li>
        <p class="goHeader cursor" @click="goTop">回到顶部</p>
      </ul> -->
    </div>
  </div>
</template>
<script>
import Tip from "../tip.vue";

import loginWay from "../common/login_way.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "pc-footer",
  components: {
    loginWay,
    Tip,
  },
  watch: {
    $route: {
      handler(newVal) {
        const { rightIsShow, footLogo } = newVal.meta;
        this.fixedState = rightIsShow ? false : true;
        this.footLogo = footLogo ? false : true;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  data() {
    return {
      maskState: false,
      goTopShow: false,
      fixedState: true,
      footLogo: true,
      siteInfo: "",
      navigation: "",
      blogroll: "",
      rightList:[]
    };
  },
  mounted() {
    localStorage.setItem("footOffsetTop", this.$refs.foot.offsetTop);
    this.getState();
    window.addEventListener("scroll", this.handleScroll);
    // if (
    //   window.location.href.indexOf("detail") != -1 ||
    //   window.location.href.indexOf("register_info") != -1
    // ) {
    //   this.fixedState = false;
    // } else {
    //   this.fixedState = true;
    // }
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
    if(this.siteInfo.footer_logo){
     this.siteInfo.footer_logo= this.siteInfo.footer_logo.replace('http:','https:')
    }
    this.navigation = JSON.parse(localStorage.getItem("navigation"));
    this.getBlogroll();
    this.getRightList()
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
    ]), //登录弹窗
    getState() {
    },
    getBlogroll() {
      this.$axios.post(`/v1/home/relevanceLink`).then((res) => {
        this.blogroll = res.data;
      });
    },
    getRightList(){
      this.$axios.post(`/v1/home/suspensionBox`).then(res=>{
        if(res.code==0){
          this.rightList = res.data
        }
      })
    },
    goUserCent() {
      if (JSON.parse(localStorage.getItem("user"))) {
        this.$router.push("/userCenter/personal_info");
      } else {
        this.ShowLoginModal();
      }
    },
    // 回到顶部
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 200) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    //回到顶部
    goTop() {
      let timer = null,
        _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 250;
          document.body.scrollTop = document.documentElement.scrollTop =
            _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
}
.footer {
  .box_footer {
    background: #202833;
    .foot_list {
      width: 1200px;
      margin: 0 auto;
      .first_menu {
        width: 100%;
        padding-top: 47px;
        justify-content: space-between;
        .menu {
          align-items: flex-start;
          ul {
            margin-right: 70px;
            li:first-child {
              font-weight: bold;
              color: #999999;
            }
            li {
              font-size: 14px;
              color: #fff;
              margin-bottom: 24px;
              cursor: pointer;
               opacity: .8;
              a {
                font-size: 14px;
                color: #fff;
                opacity: .8;
              }
            }
            li.tips{
              color: #fff;
              font-weight: bold;
               opacity: 1;
            }
          }
        }
        .right_img {
          margin-right: 69px;
          img {
            width: 142px;
            height: 142px;
          }
          ul {
            align-items: flex-start;
            li {
              margin-right: 22px;
              cursor: pointer;
            }
          }
          li:last-child {
            p.deep_logo {
              img {
                width: auto;
                height: 35px;
              }
            }
            p.phone_num {
              font-size: 24px;
              font-weight: 400;
              color: #ffffff;
              margin-top: 12px;
            }
            p.btn {
              width: 89px;
              height: 32px;
              background: #0065df;
              border-radius: 56px 56px 56px 56px;
              text-align: center;
              line-height: 32px;
              color: #fff;
              font-size: 14px;
              margin-top: 30px;
            }
          }
        }
        .code_img_list{
          ul{
            li{
              margin-right: 20px;
              p.img{
                width: 90px;
                height: 90px;
                margin: 0 auto;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
              p.text{
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                margin-top: 14px;
                
              }
            }
            li:last-child{
              margin-right: 0;
            }
          }
        }
      }
      .sec_menu {
        padding-top: 61px;
        align-items: flex-start;
        p {
          font-size: 14px;
          color: #787878;
          font-weight: bold;
          margin-right: 47px;
        }
        ul {
          flex-wrap: wrap;
          flex: 1;
          li {
            font-size: 14px;
            color: #787878;
            margin-right: 27px;
            margin-bottom: 18px;
            cursor: pointer;
            a {
              color: #787878;
            }
          }
        }
      }
    }
  }
  .deep_code {
    height: 69px;
    background: #202833;
   
    .deep_text {
      width: 1200px;
       border-top: 1px solid rgba(255,255,255,.1);
      margin: 0 auto;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #787878;
      line-height: 69px;
      // text-align: center;
    }
  }
  .fixed_menu {
    // flex: 1;
    position: fixed;
    top: 50%;
    right: 0;
    // left: 50%;
    // margin-left: 614px;
    // position: fixed;
    // top: 50%;
    // right:15%;
    z-index: 9;
    transform: translateY(-50%);
    transition: all .5s  ease-in-out;
    // ul {
    //   li {
    //     width: 70px;
    //     height: 70px;
    //     background: #ffffff;
    //     box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
    //     border-radius: 3px 3px 3px 3px;
    //     margin-bottom: 12px;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     cursor: pointer;
    //     p.text {
    //       font-size: 14px;
    //       font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    //       font-weight: 400;
    //       color: #333;
    //       text-align: center;
    //       a{
    //         color:#333;
    //       }
    //     }
    //     img{
    //       width: 100%;
    //       height: 100%;
    //       border-radius: 3px;
    //     }
    //     p.img2 {
    //       display: none;
    //     }
    //     transition: 0.8s;
    //     &:hover {
    //       p.text {
    //         color: #0676fe;
    //         a{
    //           color: #0676fe;
    //         }
    //       }
    //     }
    //     .show_text {
    //       width: 440px;
    //       min-height: 206px;
    //       background: #ffffff;
    //       box-shadow: 0px 4px 7px 1px rgba(20, 6, 5, 0.16);
    //       border-radius: 4px;
    //       position: absolute;
    //       right: 73px;
    //       top: 0;
    //       z-index: 13px;
    //       display: none;
    //       .title_icon {
    //         display: flex;
    //         align-items: center;
    //         padding: 21px;
    //         span {
    //           font-size: 22px;
    //           font-family: Source Han Sans CN;
    //           font-weight: 500;
    //           color: #254ed4;
    //           margin-left: 10px;
    //         }
    //       }
    //       .phone_list {
    //         background: #e5eaef;
    //         padding-bottom: 15px;
    //         display: flex;
    //         min-height: 132px;
    //         .left_text {
    //           padding-top: 32px;
    //           margin-left: 20px;
    //         }
    //         p.text1 {
    //           font-size: 18px;
    //           font-family: Source Han Sans CN;
    //           font-weight: 500;
    //           color: #222222;
    //           margin-bottom: 11px;
    //         }
    //         div.num {
    //           font-size: 14px;
    //           font-family: Source Han Sans CN;
    //           font-weight: 500;
    //           color: #254ed4;
    //           margin-bottom: 5px;
    //           span {
    //             color: #222;
    //             margin-right: 10px;
    //           }
    //         }
    //       }
    //       .code_img {
    //         width: 120px;
    //         height: 120px;
    //          margin-left: 22px;
    //           margin-top: 31px;
    //         img{
    //           width: 100%;
    //           height: auto;
             
    //       }
    //       }
    //     }
    //   }
    //   li.show_more:hover .show_text {
    //     display: block;
    //   }

    //   p.goHeader {
    //     width: 70px;
    //     height: 34px;
    //     background: #ffffff;
    //     box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
    //     border-radius: 3px 3px 3px 3px;
    //     text-align: center;
    //     line-height: 34px;
    //     font-size: 12px;
    //     font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    //     font-weight: 400;
    //     color: #000000;
    //   }
    // }

    .cont_ul{
      width: 80px;
      // height: 298px;
      background: #FFFFFF;
      opacity: 1;
      border: 1px solid #F4F4F5;
      padding-top: 15px;
      border-radius: 10px 0 0 10px;
      li{
        margin: 0 14px;
        margin-bottom: 13px;
        padding-bottom: 15px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
        cursor: pointer;
        p.icon{
          width: 30px;
          height: 30px;
          margin: 0 auto;
          img{
            width: 100%;
            height: auto;
          }
        }
        p.text{
          font-size: 12px;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 400;
          color: #0065DF;
          margin-top: 4px;
          text-align: center;
        }
        .show_cont_info{
          position: absolute;
          
        }
        .code_info{
          left: -197px;
          top: -12px;
          width: 180px;
          height: 193px;
          background: #FFFFFF;
          box-sizing: border-box;
          padding: 24px 34px 0;
          border-radius: 10px;
          display: none;
          p.tips{
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans, Source Han Sans;
            font-weight: 400;
            color: #3D3D3D;
          }
          p.img{
            width: 112px;
            height: 111px;
            img{
              width: 100%;
            }
          }
        }
        .phone_cont{
          left: -197px;
          top: -8px;
          border-radius: 10px;
          display: none;
          width: 180px;
          height: 81px;
          background: #FFFFFF;
          text-align: center;
          line-height: 81px;
          font-size: 20px;
          font-family: Source Han Sans, Source Han Sans;
          font-weight: 500;
          color: #333333;
        }

      }
      li.code_li:hover{
        .code_info{
          display: block;
        }
      }
      li.phone_li:hover{
        .phone_cont{
          display: block;
        }
      }
      li:last-child{
        border-bottom: none;
      }
    }
  }
  .goTop {
    top: 30%;
  }
}
// 弹框
.mask_show_box {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  p.close {
    font-size: 30px;
    color: #333;
    cursor: pointer;
    z-index: 9999;
    width: 98%;
    text-align: right;
  }
  .contBox {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 5px 0 20px;
    border-radius: 5px;
    width: 550px;
    img{
      width: 90%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    p.text {
      font-size: 18px;
      color: #333;
      margin-left: 20px;
      margin-top: -20px;
    }
    .show_text {
      margin-top: 15px;
      background: #e5eaef;
      padding: 20px;
    }
    .code_img {
      width: 120px;
      height: 120px;
      margin-left: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
