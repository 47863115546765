import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from '@/utils/axios'
import VueLazyload from 'vue-lazyload'
import 'lib-flexible'
import share from "@/utils/share.js";

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$axios = Axios //引用axios
Vue.prototype.shareWx = share //引用axios
Vue.use(VueLazyload)
Vue.config.ignoredElements = ['wx-open-launch-weapp', 'wx-open-subscribe']

//线上禁用console.log
console.log = (function (log) {
  return process.env.NODE_ENV == 'production' ? function () { } : log
}(console.log))

// router.beforeEach(async(to, from, next) => {
//   if(to.fullPath.includes('/#/')){
//     next(to.fullPath.split('/#')[1]);
//   }else{
//     next()
//   }
//   if (to.meta.content) {
//     console.log(to.meta.content,'to.meta.content')
//     const head = document.getElementsByTagName('head')[0];
//     // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
//     const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
//     if (existingKeywordsMeta) {
//         existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
//     }
//     const existingDescriptionMeta = document.querySelector('meta[name="description"]');
//     if (existingDescriptionMeta) {
//         existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
//     }

//     // 创建并设置新的关键词和描述 meta 标签
//     const keywordsMeta = document.createElement('meta');
//     keywordsMeta.setAttribute('name', 'keywords');
//     keywordsMeta.setAttribute('content', to.meta.content.keywords);
//     head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

//     const descriptionMeta = document.createElement('meta');
//     descriptionMeta.setAttribute('name', 'description');
//     descriptionMeta.setAttribute('content', to.meta.content.description);
//     head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

//     // 可选：如果需要，更新名为 "content" 的其他 meta 标签
//     const contentMeta = document.querySelector('meta[name="content"]');
//     if (contentMeta) {
//         contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
//     }
   
//   }
//   next()
// })
router.beforeEach((_to, _from, next) => {
    if(_to.fullPath.includes('/#/')){
      next(_to.fullPath.split('/#')[1]);
    }else{
      next()
    }
    if (_to.meta.content) {
      let head = document.getElementsByTagName("head");
      let meta = document.createElement("meta");
      let existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
      let existingDescriptionMeta = document.querySelector('meta[name="description"]');
      existingKeywordsMeta.content = _to.meta.content.keywords;
      existingDescriptionMeta.content = _to.meta.content.description;
      // meta.content = _to.meta.name;
      head[0].appendChild(meta);
    }
    /* 路由发生变化修改页面title */
    if (_to.meta.title) {
      document.title = _to.meta.title;
    
    }else{
      document.title = '中工云课堂';
    }
   next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
