import Vue from 'vue'
import VueRouter from 'vue-router'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC } from '@/utils/util'
Vue.use(VueRouter)

// 解决vue-router重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/pc/index.vue'),
        meta: {
          // headerIsShow: true,
          // footerIsShow: true,
          index:'1',
          title:'中工云课堂',
          content:{
            keywords:'中工云课堂',
            description:'中工云课堂',
          }
        }
      },
      //404
      {
        path: '/404',
        name: '404',
        component: () => import('../views/pc/404.vue'),
        title:'404',
          content:{
            keywords:'中工云课堂',
            description:'中工云课堂',
          }
      },
      //首页
      {
        path: '/appDownload',
        name: 'AppDownload',
        component: () => import('../views/pc/appDownload.vue'),
      },
       //模块列表
       {
        path: '/moduleList',
        name: 'moduleList',
        component: () => import('../views/pc/moduleList.vue'),
      },
      //协议
      {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('../views/pc/components/common/agreement.vue'),
      },
      //讲师
      {
        // path: '/teacher/list',
        path: '/teacherList',
        name: '/teacher/list',
        component: () => import('../views/pc/teacher/list.vue'),
        meta:{
          title:'大师来了-中工云课堂',
          content:{
            keywords:'大师来了-中工云课堂',
            description:'大师来了-中工云课堂',
          }
        }
      },
      //讲师详情
      {
        // path: '/teacher/teacher_detail',
        path: '/teacherInfo/:id',
        name: '/teacher/teacher_detail',
        component: () => import('../views/pc/teacher/teacher_detail.vue'),
        meta: {
          rightIsShow: true,
          // footerIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      //讲师搜索
      {
        path: '/teacher/teacher_search',
        name: '/teacher/teacher_search',
        component: () => import('../views/pc/teacher/teacher_search.vue'),
        title:'',
          content:{
            keywords:'',
            description:''
          }
      },
      // 注册方式
      {
        path: '/register_way',
        name: 'register_way',
        component: () => import('../views/pc/register_way.vue'),
        meta: {
          headerIsShow: true,
          rightIsShow: true,
          footLogo:true,
          title:'注册',
          content:{
            keywords:'注册',
            description:'注册'
          }
          // footerIsShow: true,
        }
      },
      // 注册方式
      {
        path: '/register_info',
        name: 'register_info',
        component: () => import('../views/pc/register_info.vue'),
        meta: {
          headerIsShow: true,
          rightIsShow: true,
          footLogo:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
          // footerIsShow: true,
        }
      },
      // 注册
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/pc/register.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 忘记密码--修改密码
      {
        path: '/changePassword',
        name: 'changePassword',
        component: () => import('../views/pc/changePassword.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true,
          title:'忘记密码',
          content:{
            keywords:'忘记密码',
            description:'忘记密码'
          }
        }
      },
      {
        path: '/training_plan',
        name: 'training_plan',
        component: () => import('../views/pc/training_plan.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      {
        path: '/knowLedge',
        name: 'knowLedge',
        component: () => import('../views/pc/landPage/knowLedge.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      {
        path: '/activityImg',
        name: 'activityImg',
        component: () => import('../views/pc/landPage/activity_img.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      {
        path: '/activityLink',
        name: 'activityLink',
        component: () => import('../views/pc/landPage/activityLink.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 个人中心
      // {
      //   path: '/userCenter/index',
      //   name: 'userInfo',
      //   component: () => import('../views/pc/userCenter/index.vue'),
      //   meta:{
      //     title:'',
      //     content:{
      //       keywords:'',
      //       description:''
      //     }
      //   }
      // },
      {
        path: '/userCenter/personal_info',
        name: 'userInfo',
        component: () => import('../views/pc/userCenter/user_info.vue'),
        meta:{
          title:'',
          rightIsShow: true,
          content:{
            keywords:'',
            description:''
          }
        },
        children:[
          {
             //个人资料0   
            path: '/userCenter/personal_info',
            name: 'personal_info',
            component: () => import('../views/pc/userCenter/userCommon/personal_info.vue'),
            meta:{
               rightIsShow: true,
              title:'个人资料-个人中心',
              content:{
                keywords:'个人资料-个人中心',
                description:'个人资料-个人中心'
              }
            }
          },{
            //账号安全1
            path: '/userCenter/account_num',
            name: 'account_num',
            component: () => import('../views/pc/userCenter/userCommon/account_num.vue'),
            meta:{
               rightIsShow: true,
              title:'账号安全-个人中心',
              content:{
                keywords:'账号安全-个人中心',
                description:'账号安全-个人中心'
              }
            }
          },{
            //收货地址2 二期
            path: '/userCenter/address',
            name: 'address',
            component: () => import('../views/pc/userCenter/userCommon/address.vue'),
            meta:{
               rightIsShow: true,
              title:'收货地址-个人中心',
              content:{
                keywords:'收货地址-个人中心',
                description:'收货地址-个人中心'
              }
            }
          },{
            //我的订单3
            path: '/userCenter/my_order',
            name: 'my_order',
            component: () => import('../views/pc/userCenter/userCommon/my_order.vue'),
            meta:{
               rightIsShow: true,
              title:'我的订单-个人中心',
              content:{
                keywords:'我的订单-个人中心',
                description:'我的订单-个人中心'
              }
            }
          },{
            //我的积分4
            path: '/userCenter/my_integral',
            name: 'my_integral',
            component: () => import('../views/pc/userCenter/userCommon/my_integral.vue'),
            meta:{
               rightIsShow: true,
              title:'我的积分-个人中心',
              content:{
                keywords:'我的积分-个人中心',
                description:'我的积分-个人中心'
              }
            }
          },{
            //我的会员5
            path: '/userCenter/my_member',
            name: 'my_member',
            component: () => import('../views/pc/userCenter/userCommon/my_member.vue'),
            meta:{
               rightIsShow: true,
              title:'我的会员-个人中心',
              content:{
                keywords:'我的会员-个人中心',
                description:'我的会员-个人中心'
              }
            }
          },{
            //我的邀请6 二期
            path: '/userCenter/my_invitation',
            name: 'my_invitation',
            component: () => import('../views/pc/userCenter/userCommon/my_invitation.vue'),
            meta:{
               rightIsShow: true,
              title:'',
              content:{
                keywords:'',
                description:''
              }
            }
          },{
            //消息通知7
            path: '/userCenter/news_notice',
            name: 'news_notice',
            component: () => import('../views/pc/userCenter/userCommon/news_notice.vue'),
            meta:{
               rightIsShow: true,
              title:'',
              content:{
                keywords:'',
                description:''
              }
            }
          },{
            //反馈信息8
            path: '/userCenter/feedback_info',
            name: 'feedback_info',
            component: () => import('../views/pc/userCenter/userCommon/feedback_info.vue'),
            meta:{
               rightIsShow: true,
              title:'',
              content:{
                keywords:'',
                description:''
              }
            }
          },
        ]
      },
      //支付宝支付页面 aliPay
      {
        path: '/aliPay',
        name: 'AliPay',
        component: () => import('../views/pc/aliPay.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 选课中心
      {
        // path: '/course/choose_course',
        path: '/courseCenter',
        name: 'course/choose_course',
        component: () => import('../views/pc/course/choose_course.vue'),
        meta:{
          title:'课程中心-中工云课堂',
          content:{
            keywords:'课程中心-中工云课堂',
            description:'课程中心-中工云课堂',
          }
        }
      },
      // 选课中心-详情
      {
        // path: '/course/detail',
        path: '/courseInfo/:id',
        name: 'course/detail',
        component: () => import('../views/pc/course/detail.vue'),
        meta: {
          rightIsShow: true,
          // footerIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:'',
          }
        }
      },
      // 选课中心-录播课播放
      {
        path: '/course/video_play',
        name: 'course/video_play',
        component: () => import('../views/pc/course/video_play.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 学习中心
      {
        path: '/learnCenter/learn_record',
        name: 'learnCenter/learn_record',
        component: () => import('../views/pc/learnCenter/index.vue'),
        meta:{
          keepAlive: true,
          rightIsShow: true,
          title:'学习中心',
          content:{
            keywords:'学习中心',
            description:'学习中心'
          }
        },
        children:[
          {
            path: '/learnCenter/learn_record',
            name: 'learnCenter/learn_record',
            component: () => import('../views/pc/learnCenter/learn_record.vue'),
            meta:{
              keepAlive: true,
               rightIsShow: true,
              title:'学习记录-学习中心',
              content:{
                keywords:'学习记录-学习中心',
                description:'学习记录-学习中心'
              }
            },
          },{
            path: '/learnCenter/my_class',
            name: 'learnCenter/my_class',
            component: () => import('../views/pc/learnCenter/my_class.vue'),
            meta:{
              keepAlive: true,
               rightIsShow: true,
              title:'我的班级-学习中心',
              content:{
                keywords:'我的班级-学习中心',
                description:'我的班级-学习中心'
              }
            },
          },{
            path: '/learnCenter/follow',
            name: 'learnCenter/follow',
            component: () => import('../views/pc/learnCenter/follow.vue'),
            meta:{
              keepAlive: true,
               rightIsShow: true,
              title:'收藏/关注-学习中心',
              content:{
                keywords:'收藏/关注-学习中心',
                description:'收藏/关注-学习中心'
              }
            },
          },{
            path: '/learnCenter/my_exam',
            name: 'learnCenter/my_exam',
            component: () => import('../views/pc/learnCenter/my_exam.vue'),
            meta:{
              keepAlive: true,
               rightIsShow: true,
              title:'我的考试-学习中心',
              content:{
                keywords:'我的考试-学习中心',
                description:'我的考试-学习中心'
              }
            },
          },{
            path: '/learnCenter/buy_course',
            name: 'learnCenter/buy_course',
            component: () => import('../views/pc/learnCenter/buy_course.vue'),
            meta:{
              keepAlive: true,
               rightIsShow: true,
              title:'已购买课程-学习中心',
              content:{
                keywords:'已购买课程-学习中心',
                description:'已购买课程-学习中心'
              }
            },
          }
        ]
      },
      // {
      //   path: '/learnCenter/list',
      //   name: 'learnCenter/list',
      //   component: () => import('../views/pc/learnCenter/list.vue'),
      //   meta:{
      //     keepAlive: true,
      //     title:'',
      //     content:{
      //       keywords:'',
      //       description:''
      //     }
      //   }
      // },
      // 考试
      {
        path: '/learnCenter/text',
        name: 'learnCenter/text',
        component: () => import('../views/pc/learnCenter/text.vue'),
        meta:{
          keepAlive: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 考试详情
      {
        path: '/learnCenter/exam_detail',
        name: 'learnCenter/exam_detail',
        component: () => import('../views/pc/learnCenter/exam_detail.vue'),
        meta:{
          keepAlive: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 专题
      {
        // path: '/special/list',
        path: '/subjectList',
        name: 'subject/list',
        component: () => import('../views/pc/subject/list.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 专题-详情
      {
        // path: '/special/detail',
        path: '/subjectInfo/:id',
        name: 'subject/detail',
        component: () => import('../views/pc/subject/detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 活动
      {
        path: '/activity/list',
        name: 'activity/list',
        component: () => import('../views/pc/activity/list.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },{
        path: '/activity/detail',
        name: 'activity/detail',
        component: () => import('../views/pc/activity/detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },{
        path: '/activity/subject',
        name: 'activity/subject',
        component: () => import('../views/pc/activity/subject.vue'),
        meta:{
          headerIsShow:true,
          footerIsShow:true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 垂直领域*品牌合作
      {
        path: '/brand/list',
        name: 'brand/list',
        component: () => import('../views/pc/brand/list.vue'),
        meta:{
          title:'专题培训班-中工云课堂',
          content:{
            keywords:'专题培训班-中工云课堂',
            description:'专题培训班-中工云课堂'
          }
        }
      },
      {
        path: '/brand/detail',
        name: 'brand/detail',
        component: () => import('../views/pc/brand/detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 专项认证列表
      {
        path: '/special_ident/list',
        name: 'special_ident/list',
        component: () => import('../views/pc/special_ident/list.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 专项认证详情
      {
        path: '/special_ident/detail',
        name: 'special_ident/detail',
        component: () => import('../views/pc/special_ident/detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 直播列表
      {
        // path: '/live_broadcast/list',
        path: '/liveList',
        name: 'live_broadcast/list',
        component: () => import('../views/pc/live_broadcast/list.vue'),
        meta:{
          title:'直播公开课',
          content:{
            keywords:'直播公开课',
            description:'直播公开课'
          }
        }
      },
      // 直播列表
      {
        path: '/live_broadcast/live_play/:no/:id',
        name: 'live_broadcast/live_play',
        component: () => import('../views/pc/live_broadcast/live_play.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      
      // 直播详情
      // {
      //   path: '/live_broadcast/detail',
      //   name: 'live_broadcast/detail',
      //   component: () => import('../views/pc/live_broadcast/detail.vue'),
      // },
      // 云选课列表
      {
        // path: '/cloud_select_course/list',
        path: '/cloudCourse',
        name: 'cloud_select_course/list',
        component: () => import('../views/pc/cloud_select_course/list.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 企业研学列表
      {
        path: '/cloud_select_course/company_list',
        name: 'cloud_select_course/company_list',
        component: () => import('../views/pc/cloud_select_course/company_list.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 企业研学列表
      {
        path: '/cloud_select_course/company_register',
        name: 'cloud_select_course/company_register',
        component: () => import('../views/pc/cloud_select_course/company_register.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 企业详情
      {
        path: '/cloud_select_course/company_detail',
        name: 'cloud_select_course/company_detail',
        component: () => import('../views/pc/cloud_select_course/company_detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 企业研学详情
      {
        path: '/cloud_select_course/course_detail',
        name: 'cloud_select_course/course_detail',
        component: () => import('../views/pc/cloud_select_course/course_detail.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 订单
      {
        path: '/order_page/order',
        name: 'order_page/order',
        component: () => import('../views/pc/order_page/order.vue'),
        meta:{
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 企业vip
      {
        path: '/company_vip',
        name: 'company_vip',
        component: () => import('../views/pc/landPage/company_vip.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }

        }
      },
      
      // 个人vip
      {
        path: '/personal_vip',
        name: 'personal_vip',
        component: () => import('../views/pc/landPage/personal_vip.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 云选课
      {
        path: '/course_learn',
        name: 'course_learn',
        component: () => import('../views/pc/course_learn.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 商城首页
      {
        path: '/shop_content/index',
        name: 'shop_content/index',
        component: () => import('../views/pc/shop_content/index.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 商城列表页
      {
        path: '/shop_content/list',
        name: 'shop_content/list',
        component: () => import('../views/pc/shop_content/list.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 商品详情
      {
        path: '/shop_content/detail',
        name: 'shop_content/detail',
        component: () => import('../views/pc/shop_content/detail.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
      // 商品支付
      {
        path: '/shop_content/pay_order',
        name: 'shop_content/pay_order',
        component: () => import('../views/pc/shop_content/pay_order.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
       // 落地页
       {
        path: '/company_class',
        name: 'company_class',
        component: () => import('../views/pc/landPage/company_class.vue'),
        meta:{
          rightIsShow: true,
          title:'',
          content:{
            keywords:'',
            description:''
          }
        }
      },
    ]
  }
];

const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/m/index.vue'),
      },
      //首页
      {
        path: '/appDownload',
        name: 'AppDownload',
        component: () => import('../views/m/appDownload.vue'),
      },
       //模块列表
       {
        path: '/moduleList',
        name: 'moduleList',
        component: () => import('../views/m/moduleList.vue'),
      },
      //协议
      {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('../views/m/agreement.vue'),
      },
      //搜索
      {
        path: '/search',
        name: 'Search',
        component: () => import('../views/m/search.vue'),
      },
      //搜索
      {
        path: '/bindPhone/:openid',
        name: 'bindPhone',
        component: () => import('../views/m/bindPhone.vue'),
      },
      //考试
      {
        path: '/exam/:id',
        name: 'Exam',
        component: () => import('../views/m/exam.vue'),
      },
      //考试记录
      {
        path: '/examRecord/:id',
        name: 'ExamRecord',
        component: () => import('../views/m/exam.vue'),
      },
      //考试详情
      {
        path: '/examInfo/:id',
        name: 'ExamInfo',
        component: () => import('../views/m/examInfo.vue'),
      },
      //我的考试
      {
        path: '/myExam',
        name: 'MyExam',
        component: () => import('../views/m/myExam.vue'),
      },
      //我的学习
      {
        path: '/myLearn',
        name: 'MyLearn',
        component: () => import('../views/m/myLearn.vue'),
      },
      //我的订单
      {
        path: '/orderList',
        name: 'OrderList',
        component: () => import('../views/m/orderList.vue'),
      },
      //订单详情
      {
        path: '/orderInfo/:id/:type',
        name: 'OrderInfo',
        component: () => import('../views/m/orderInfo.vue'),
      },
      //我的班级列表
      {
        path: '/myClassList',
        name: 'MyClassList',
        component: () => import('../views/m/myClassList.vue'),
      },
      //我的班级详情
      {
        path: '/myClass/:id/:state',
        name: 'MyClass',
        component: () => import('../views/m/myClass.vue'),
      },
      //讲师列表
      {
        path: '/teacherList',
        name: 'TeacherList',
        component: () => import('../views/m/teacherList.vue'),
      },
      //讲师详情
      {
        path: '/teacherInfo/:id',
        name: 'TeacherInfo',
        component: () => import('../views/m/teacherInfo.vue'),
      },
      //专题列表
      {
        path: '/subjectList',
        name: 'SubjectList',
        component: () => import('../views/m/subjectList.vue'),
      },
      //专题详情
      {
        path: '/subjectInfo/:id',
        name: 'SubjectInfo',
        component: () => import('../views/m/subjectInfo.vue'),
      },
      //已购课程
      {
        path: '/purchasedCourse',
        name: 'PurchasedCourse',
        component: () => import('../views/m/myLearn.vue'),
      },
      //直播课堂
      {
        path: '/liveList',
        name: 'liveList',
        component: () => import('../views/m/liveList.vue'),
      },
      //直播页
      {
        // /live/:no
        path: '/live_broadcast/live_play/:no/:id',
        name: 'live',
        component: () => import('../views/m/live.vue'),
      },
      //收藏
      {
        path: '/collect/:tabindex',
        name: 'Collect',
        component: () => import('../views/m/collect.vue'),
      },
      //注册
      {
        path: '/register_info',
        name: 'Registered',
        component: () => import('../views/m/registered.vue'),
      },
      //选课中心
      {
        path: '/courseCenter',
        name: 'courseCenter',
        component: () => import('../views/m/courseCenter.vue'),
      },
      //企业研学
      {
        // path: '/enterpriseResearch/:id',
        path: '/cloud_select_course/company_detail',
        name: 'EnterpriseResearch',
        component: () => import('../views/m/enterpriseResearch.vue'),
      },
      //企业列表
      {
        path: '/enterpriseList',
        name: 'EnterpriseResearch',
        component: () => import('../views/m/enterpriseList.vue'),
      },
      
      //云选课详情
      {
        path: '/cloudCourseInfo/:type/:id',
        name: 'cloudCourseInfo',
        component: () => import('../views/m/cloudCourseInfo.vue'),
      },
      //云选课讲师详情
      {
        path: '/cloudTeacherInfo/:id',
        name: 'CloudTeacherInfo',
        component: () => import('../views/m/teacherInfo.vue'),
      },
      //云选课
      {
        path: '/cloudCourse',
        name: 'CloudCourse',
        component: () => import('../views/m/cloudCourse.vue'),
      },
      //预约登记
      {
        path: '/reserve/:type/:id',
        name: 'reserve',
        component: () => import('../views/m/reserve.vue'),
      },
      //学习中心
      {
        path: '/learnCenter',
        name: 'LearnCenter',
        component: () => import('../views/m/learnCenter.vue'),
      },
      //用户中心
      {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('../views/m/userCenter/index.vue'),
      },
      //课程详情
      {
        path: '/courseInfo/:id',
        name: 'CourseInfo',
        component: () => import('../views/m/courseInfo.vue'),
      },
      // 测试
      {
        path: '/courseInfo_text/:id',
        name: 'CourseInfo_text',
        component: () => import('../views/m/courseInfo_text.vue'),
      },
      {
        path: '/training_plan',
        name: 'training_plan',
        component: () => import('../views/m/training_plan.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true
        }
      },
      {
        path: '/knowLedge',
        name: 'knowLedge',
        component: () => import('../views/m/landPage/knowLedge.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true
        }
      },
      {
        path: '/activityImg',
        name: 'activityImg',
        component: () => import('../views/m/landPage/activity_img.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true
        }
      },
      {
        path: '/activityLink',
        name: 'activityLink',
        component: () => import('../views/m/landPage/activityLink.vue'),
        meta:{
          rightIsShow: true,
          footLogo:true
        }
      },
      // 落地页
      {
        path: '/company_class',
        name: 'company_class',
        component: () => import('../views/m/landPage/company_class.vue'),
        meta:{
          rightIsShow: true,

        }
      },
       // 活动
       {
        path: '/activity/list',
        name: 'activity/list',
        component: () => import('../views/m/activity/list.vue'),
      },{
        path: '/activity/detail',
        name: 'activity/detail',
        component: () => import('../views/m/activity/detail.vue'),
      },{
        path: '/activity/signUp',
        name: 'activity/signUp',
        component: () => import('../views/m/activity/signUp.vue'),
      },{
        path: '/activity/subject',
        name: 'activity/subject',
        component: () => import('../views/m/activity/subject.vue'),

      },{
        path: '/activity/cashier',
        name: 'activity/cashier',
        component: () => import('../views/m/activity/cashier.vue'),
      },{
        path: '/activity/codeImg',
        name: 'activity/codeImg',
        component: () => import('../views/m/activity/codeImg.vue'),
      },
      // 垂直领域*品牌合作
      {
        path: '/brand/list',
        name: 'brand/list',
        component: () => import('../views/m/brand/list.vue'),
      },
      {
        path: '/brand/detail',
        name: 'brand/detail',
        component: () => import('../views/m/brand/detail.vue'),
      },
      //个人资料
      {
        path: '/userData',
        name: 'UserData',
        component: () => import('../views/m/userCenter/userData.vue'),
      },
      //资料修改
      {
        path: '/userDataEdit/:editType',
        name: 'UserDataEdit',
        component: () => import('../views/m/userCenter/userDataEdit.vue'),
      },
      //修改公司信息
      {
        path: '/userCompanyEdit',
        name: 'UserCompanyEdit',
        component: () => import('../views/m/userCenter/userCompanyEdit.vue'),
      },
      //账号安全
      {
        path: '/accountSecurity',
        name: 'AccountSecurity',
        component: () => import('../views/m/userCenter/accountSecurity.vue'),
      },
      //修改密码
      {
        path: '/editPassword',
        name: 'EditPassword',
        component: () => import('../views/m/userCenter/editPassword.vue'),
      },
      //找回密码
      {
        path: '/retrievePassword',
        name: 'RetrievePassword',
        component: () => import('../views/m/retrievePassword.vue'),
      },
      //绑定手机号
      {
        path: '/editPhone',
        name: 'EditPhone',
        component: () => import('../views/m/userCenter/editPhone.vue'),
      },
      //实名认证
      {
        path: '/verified',
        name: 'Verified',
        component: () => import('../views/m/userCenter/verified.vue'),
      },
      //消息通知
      {
        path: '/message',
        name: 'Message',
        component: () => import('../views/m/userCenter/message.vue'),
      },
      //我的会员
      {
        path: '/myVip',
        name: 'MyVip',
        component: () => import('../views/m/userCenter/myVip.vue'),
      },
      //开通会员
      {
        path: '/openVip/',
        name: 'OpenVip',
        component: () => import('../views/m/userCenter/openVip.vue'),
      },
      //开通会员
      {
        path: '/renewalVip',
        name: 'RenewalVip',
        component: () => import('../views/m/userCenter/openVip.vue'),
      },
      //收银台
      {
        path: '/cashier',
        name: 'Cashier',
        component: () => import('../views/m/cashier.vue'),
      },{
        path: '/cashier_app',
        name: 'Cashier_app',
        component: () => import('../views/m/cashier_app.vue'),
      },
      //意见反馈
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/m/userCenter/feedback.vue')
      },
      //添加反馈
      {
        path: '/addFeedback',
        name: 'AddFeedback',
        component: () => import('../views/m/userCenter/addFeedback.vue')
      },
      //我的积分
      {
        path: '/myIntegral',
        name: 'MyIntegral',
        component: () => import('../views/m/userCenter/myIntegral.vue')
      },
      //签到
      {
        path: '/signIn',
        name: 'SignIn',
        component: () => import('../views/m/userCenter/myIntegral.vue')
      },
      //兴趣选择
      {
        path: '/interest',
        name: 'interest',
        component: () => import('../views/m/userCenter/interest.vue')
      },
      //确认订单
      {
        path: '/confirmOrder/:id',
        name: 'ConfirmOrder',
        component: () => import('../views/m/confirmOrder.vue')
      },

        // 商城首页
        {
          path: '/shop_content/index',
          name: 'shop_content/index',
          component: () => import('../views/m/shop_content/index.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },
        // 商城列表页
        {
          path: '/shop_content/list',
          name: 'shop_content/list',
          component: () => import('../views/m/shop_content/list.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },
        // 商品详情
        {
          path: '/shop_content/detail',
          name: 'shop_content/detail',
          component: () => import('../views/m/shop_content/detail.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },
        // 商品收货地址
        {
          path: '/shop_content/address',
          name: 'shop_content/address',
          component: () => import('../views/m/shop_content/address.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },
        // 商品收货地址
        {
          path: '/shop_content/address_detail',
          name: 'shop_content/address_detail',
          component: () => import('../views/m/shop_content/address_detail.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },
        // 商品支付
        {
          path: '/shop_content/pay_order',
          name: 'shop_content/pay_order',
          component: () => import('../views/m/shop_content/pay_order.vue'),
          meta:{
            rightIsShow: true,
  
          }
        },


         // 个人vip
      {
        path: '/personal_vip',
        name: 'personal_vip',
        component: () => import('../views/m/landPage/personal_vip.vue'),
        meta:{
          rightIsShow: true,

        }
      },

       // 企业vip
       {
        path: '/company_vip',
        name: 'company_vip',
        component: () => import('../views/m/landPage/company_vip.vue'),
        meta:{
          rightIsShow: true,

        }
      },

      // 立领好礼
      {
        path: '/llhl',
        name: 'llhl',
        component: () => import('../views/m/landPage/llhl.vue'),
        meta:{
          rightIsShow: true,

        }
      },
       // 智改数转
       {
        path: '/zgsz',
        name: 'zgsz',
        component: () => import('../views/m/landPage/zgsz.vue'),
        meta:{
          rightIsShow: true,

        }
      },
       // 云选课
       {
        path: '/course_learn',
        name: 'course_learn',
        component: () => import('../views/m/landPage/course_learn.vue'),
        meta:{
          rightIsShow: true,

        }
      },
       // 云选课
       {
        path: '/wxH5',
        name: 'wxH5',
        component: () => import('../views/m/wxH5.vue'),
        meta:{
          rightState: true,

        }
      },
       // 今日推荐
       {
        path: '/recommend',
        name: 'recommend',
        component: () => import('../views/m/recommend.vue'),
        meta:{
          rightState: true,

        }
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: IsPC() ? routes_pc : routes_m,
  base: '/' //这里写你服务器挂载的地址
})

export default router
