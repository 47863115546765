<template>
  <div class="m-home">
    <Login v-if="mLoginModal.show" />
    <Tip
      :text="tipModal.text"
      :ico="tipModal.ico"
      :showBtns="tipModal.showBtns"
      v-if="tipModal.show"
      :fun="tipModal.fun"
      :confirmFun="tipModal.confirmFun"
      :confirmBtnText="tipModal.confirmBtnText"
      :cancelFun="tipModal.cancelFun"
      :cancelBtnText="tipModal.cancelBtnText"
    />
    <router-view />
    <div class="right_box_fixed" v-if="rightState">
      <div class="cont_list">
        <p class="close" @click="listState = !listState">
          <span v-if="listState">〉</span>
          <span v-else>〈 </span>
        </p>
        <ul v-if="listState">
          <li class="item" v-for="item in rightList" :key="item.id">
            <!-- <a href="">定制员工培训计划</a> -->
            <a :href="item.url" target="_blank" rel="noopener noreferrer">
              <img :src="item.m_image" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Login from "@/views/m/common/login.vue";
import Tip from "@/views/m/common/tip.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "m-home",
  components: {
    Login,
    Tip,
  },
  data() {
    return {
      hrefUrl: "",
      listState: true,
      rightList: [],
      rightState: true,
    };
  },
  watch: {
    $route: {
      handler() {
        this.hrefUrl = window.location.href;
        if (this.hrefUrl.includes("wxH5") || this.hrefUrl.includes("appDownload")) {
          this.rightState = false;
        } else {
          this.rightState = true;
        }
        this.changeUrl();
      },
       immediate: true,
    },
  },
  mounted() {
    this.tokenValidity(); //根据token有效期判断是否登录
    this.getWebsiteInfo(); //获取网站配置信息
    this.getRightList();
  },
  methods: {
    getRightList() {
      this.$axios.post(`/v1/home/suspensionBox`).then((res) => {
        if (res.code == 0) {
          this.rightList = res.data;
        }
      });
    },
    changeUrl() {
      const shareObj = {
        title: "中工云课堂", // 分享标题
        desc: "职业技能提升平台", // 分享描述
        link2: this.hrefUrl,
      };
      this.shareWx(shareObj);
    },
    ...mapMutations(["ClearUserInfo", "ShowTipModal", "GetUserInfo"]),
    getWebsiteInfo() {
      //获取网站配置信息
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 2 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        localStorage.setItem("navigation", JSON.stringify(res.data.navigation));
      });
    },
    //根据token有效期判断是否登录
    tokenValidity() {
      if (localStorage.getItem("auth")) {
        let nowDate = Date.parse(new Date()) / 1000;

        if (
          nowDate - localStorage.getItem("logoDate") >
          JSON.parse(localStorage.getItem("auth")).expire
        ) {
          this.ClearUserInfo();
        }
      }
    },
  },
  computed: {
    ...mapState(["tipModal", "mLoginModal", "userInfo"]),
  },
};
</script>
<style lang="scss" scoped>

.right_box_fixed {
  // display: none;
  position: fixed;
  right: 0;
  top: 50%;
  font-size: 15px;
  z-index: 99;
  display: none;
  .cont_list {
    position: relative;
    min-height: 70px;
    display: flex;
    align-items: center;
  }
  p.close {
    width: 20px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #333;
    background: rgba(0, 0, 0, 0.1);
    line-height: 40px;
    border-radius: 5px 0 0 5px;
    span {
      text-align: center;
      color: #000;
    }
  }
  li {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #333;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>
.m-home {
  color: #000;
  font-weight: 400;
}
::v-deep a {
  color: #0065df;
}
//页面灰背景
::v-deep .page-grey-bg {
  background-color: #f9f9f9;
}
//灰色分割线
::v-deep .grey-line {
  width: 100%;
  height: 8px;
  background-color: #f9f9f9;
}
//可视区高
::v-deep .visual-area-height {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow-y: auto;
}
::v-deep .marks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .mark {
    padding: 5px 8px 6px;
    background-color: #eda220;
    border-radius: 3px;
    font-size: 10px;
    color: #fff;
    margin-right: 5px;
  }
}
//表单
::v-deep .form-box {
  padding: 26px 18px 0;
  .form-title {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }
  &.type1 {
    .form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      &.has-input {
        justify-content: flex-start;
        .form-input {
          width: 200px;
          height: 24px;
          font-size: 14px;
          margin-left: 22px;
        }
        .form-input-clear {
          width: 24px;
          margin-left: auto;
        }
      }
    }

    .form-text {
      font-size: 15px;
      width: 200px;
      overflow: hidden;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 20px;
      position: relative;
      &.class-arrows::after {
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: 4px;
      }
      .placeholder {
        color: #999;
      }
    }
  }
  &.type2 {
    .form {
      margin-bottom: 30px;
    }
    .form-title {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .err-tip {
      font-size: 15px;
      color: #e15416;
    }
  }
}
//伪类三角
::v-deep .class-arrows::after {
  content: "";
  display: block;
  width: 8px;
  height: 16px;
  background: url(~@/assets/m/arrows-right-grey.png) no-repeat;
  background-size: 100%;
}
::v-deep .input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .clear {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: url(~@/assets/m/wrong-white.png) no-repeat center center
      rgba(126, 134, 158, 0.25);
    background-size: 22px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eyes {
    width: 20px;
    height: 20px;
    background: url(~@/assets/m/eyes1.png) left center no-repeat;
    background-size: 100%;
    &.open {
      background: url(~@/assets/m/eyes2.png) left center no-repeat;
      background-size: 100%;
    }
  }
  &.type1 {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    .input {
      width: 93%;
      height: 25px;
      font-size: 15px;
      color: #000;
    }
  }
  &.type2 {
    width: 100%;
    padding: 0 15px;
    border: 1px solid #e5e5e5;
    margin-top: 15px;
    .input {
      width: 93%;
      padding: 13px 0 14px;
      font-size: 15px;
      &::placeholder {
        color: #999;
      }
    }
    &.ver-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 200px;
      }
      .code {
        font-size: 15px;
        color: #0065df;
        white-space: nowrap;
      }
    }
  }
  &.disabled {
    background-color: rgba(59, 59, 59, 0.1);
    .input {
      background: none;
    }
  }
}
//大按钮
::v-deep .sub-btn {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #0065df;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  margin: 0 auto;
  &.disable {
    background-color: #99c1f2;
  }
}
::v-deep .checkbox {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: url(~@/assets/m/tick-white.png) no-repeat center 52% #999;
  background-size: 6px;
  input {
    opacity: 0;
  }
  &.checked {
    background-color: #0065df;
  }
  &.type2 {
    width: 18px;
    height: 18px;
    border: 2px solid #bbb;
    background: none;
    &.active {
      border: none;
      background: url(~@/assets/m/tick-white.png) no-repeat center center
        #0065df;
      background-size: 12px;
    }
  }
}
//隐私协议
::v-deep .privacy-policy {
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  .checkbox {
    margin-right: 6px;
  }
}
::v-deep .top-bar-btn {
  padding: 0 18px;
  font-size: 14px;
  line-height: 48px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}
::v-deep .mark-img-box {
  position: relative;
  overflow: hidden;
  .img-mask {
    width: 56px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-bottom-right-radius: 8px;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    &.property2 {
      background: linear-gradient(90deg, #64da9f 0%, #6ef0ae 100%);
    }
    &.property3 {
      background: linear-gradient(90deg, #5d42ff 0%, #9f8fff 100%);
    }
    &.property4 {
      background: linear-gradient(90deg, #ff7050 0%, #ffa38f 100%);
    }
  }
}
//首页通用模块样式
::v-deep .modal-box {
  width: 100%;
  padding: 24px 18px 0;
  border-top: 10px solid #F2F4F7;
  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .more {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999;
      &.class-arrows::after {
        width: 5px;
        height: 10px;
        margin-left: 4px;
      }
    }
  }
  .live-list{
    padding: 24px 0;
    .list-info{
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
    .info-img-box{
      width: 82px;
      height: 100px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }
    .info-img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .info-content{
      width: 239px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info-title{
        font-size: 15px;
        color: #333;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 6px;
      }
      .info-subtitle{
        font-size: 14px;
        color: #666;
        line-height: 16px;
        &.speaker{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 15px;
        }
        &.date{
          margin-bottom: auto;
        }
        .reserve{
          width: 86px;
          height: 30px;
          background: #0065DF;
          border-radius: 23px 23px 23px 23px;
          font-size: 14px;
          color: #FFF;
        }
      }
    }
  }
  .modal-list{
    &.one-column{
      .modal-list-info{
        padding: 20px 0;
        border-bottom: 1px solid #EBEBEB;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
      }
      .info-img-box{
        width: 138px;
        height: 76px;
        border-radius: 4px;
        overflow: hidden;
        .info-img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info-content{
        width: 190px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .info-title{
        font-size: 15px;
        font-weight: 500;
        color: #333;
        line-height: 20px;
      }
      .info-subtitle{
        font-size: 12px;
        color: #999;
        line-height: 14px;
        &.desc{
          font-size: 12px;
          color: #666;
          line-height: 14px;
        }
      }
      .info-price{
        .now{
          font-size: 14px;
          font-weight: 500;
          color: #E15416;
          line-height: 21px;
        }
        .original{
          font-size: 10px;
          color: #999;
          line-height: 12px;
          margin-left: 8px;
        }
      }
    }
  }
  &.big-card {
    margin-bottom: -20px;
    .card {
      width: 100%;
      background-color: #fff;
      margin-bottom: 20px;
    }
    .card-img-box {
      width: 100%;
      height: 190px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      position: relative;
      .card-img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
      }
      .card-img-shade {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, #000000 99%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .card-img-mark {
        width: 75px;
        height: 28px;
        background-color: #e15416;
        border-bottom-right-radius: 10px;
        line-height: 28px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        font-weight: 500;
      }
      .card-img-title {
        width: 100%;
        padding: 0 15px 14px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        .img {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 6px;
        }
        .title {
          width: 320px;
          font-size: 15px;
          color: #fff;
        }
      }
    }
    .card-content {
      padding: 15px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.08);
      .lecturer {
        font-size: 15px;
      }
      .date {
        padding-top: 4px;
        font-weight: bold;
        font-size: 12px;
      }
      .reserve {
        padding: 0 13px;
        height: 30px;
        line-height: 30px;
        background: #0065df;
        border-radius: 15px;
        font-size: 14px;
        color: #fff;
      }
      .price {
        padding-top: 6px;
        .current-price {
          font-size: 18px;
          font-weight: bold;
          color: #e15416;
        }
        .original-price {
          font-size: 12px;
          font-weight: bold;
          color: #999;
          margin-left: 20px;
          text-decoration: line-through;
        }
      }
    }
    &.project {
      padding-top: 44px;
      .card-img-box {
        border-radius: 10px;
      }
      .lecturer {
        font-size: 15px;
      }
      .card-content {
        box-shadow: none;
        padding: 10px 0;
        .content {
          width: 100%;
          p{
            font-size: 14px;
          }
        }
      }
    }
  }
  &.has-scrolling {
    padding-left: 0;
    padding-right: 0;
    .modal-title {
      padding-left: 18px;
      padding-right: 18px;
    }
    .modal-list {
      padding: 18px;
      padding-right: 0;
      margin-top: -18px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        //隐藏滚动条
        display: none;
      }
    }
    .modal-list-info {
      padding: 15px;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      margin-right: 15px;
    }
    .modal-info-img {
      width: 120px;
      height: 168px;
      border-radius: 8px;
    }
    .certificate {
      margin-top: 10px;
      font-size: 12px;
      color: #999;
      padding-left: 12px;
      background: url(~@/assets/m/medal.png) no-repeat left center;
      background-size: auto 11px;
    }
    .special {
      width: 100%;
      //height: 36px;
      padding-top: 10px;
      line-height: 18px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  &.single-row-insets {
    margin-bottom: -25px;
    .card {
      margin-bottom: 25px;
    }
    .mark-img-box {
      border-radius: 8px;
      float: left;
    }
    .mark-img-box,
    .card-img {
      width: 157px;
      height: 88px;
    }
    .card-content {
      width: 166px;
      float: right;
    }
    .card-content-title {
      height: 36px;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
    }
    .card-content-center {
      padding-top: 8px;
      font-size: 12px;
      color: #7b7b7b;
    }
    .card-content-bottom {
      padding-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .money {
        font-size: 18px;
        color: #e15416;
      }
      .unit {
        font-size: 12px;
      }
      .views-number {
        font-size: 12px;
        color: #999;
      }
    }
  }
  &.has-swiper {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -18px;
    overflow: hidden;
    .modal-title {
      padding: 0 18px;
    }
    .modal-swiper {
      width: 275px;
      padding: 20px 0 18px 18px;
      overflow: initial;
      margin: 0;
      .slide {
        width: 100%;
        height: 126px;
        padding: 15px;
        background: linear-gradient(
          180deg,
          #e3f0ff 0%,
          #f0f7ff 18%,
          #ffffff 100%
        );
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        overflow: hidden;
        margin-right: 15px;
      }
      .slide-img {
        width: 96px;
        height: 96px;
        object-fit: cover;
        border-radius: 50%;
        float: left;
      }
      .slide-content {
        width: 120px;
        float: right;
      }
      .slide-title {
        font-size: 15px;
        color: #000;
        font-weight: bold;
      }
      .slide-intr {
        padding-top: 8px;
        font-size: 12px;
        color: #000;
        height: 38px;
      }
      .slide-btn-box {
        padding-top: 8px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
      }
      .slide-btn {
        width: 86px;
        height: 30px;
        border-radius: 15px;
        background-color: #0065df;
        font-size: 14px;
        color: #fff;
        line-height: 30px;
        font-size: 14px;
      }
    }
  }
  &.system {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -14px;
    .modal-title {
      padding: 0 18px 20px;
    }
    .classify-swiper {
      padding: 0 18px 20px;
      .slide {
        width: auto;
        .slide-title {
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          border-radius: 15px;
          background-color: #f5f5f5;
          margin-right: 8px;
          &.active {
            background: rgba(0, 101, 223, 0.1);
            color: #0065df;
          }
        }
      }
    }
    .system-course-list {
      width: 100%;
      padding: 0 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .system-course-info {
        width: 164px;
        margin-bottom: 30px;
      }
      .mark-img-box,
      .system-course-img {
        width: 100%;
        height: 90px;
        border-radius: 4px;
        overflow: hidden;
      }
      .system-course-chapter {
        font-size: 12px;
        line-height: 15px;
        white-space: nowrap;
        overflow: hidden;
        color: #999;margin-top: 4px;
      }
      .system-course-title {
        height: 36px;
        font-size: 14px;
        color: #333;
        font-weight: 500;
        line-height: 18px;
        margin-top: 6px;
        &.a-single-ellipsis{
          height: auto;
        }
      }
      .system-course-bottom {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
      .system-course-price {
        font-size: 16px;
        color: #E15416;
        line-height: 24px;
      }
      .system-course-otherinfo {
        font-size: 13px;
        color: #999;
        line-height: 15px;
        margin-left: 12px;
      }
    }
  }
}
//进度条
::v-deep .progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .progress-bg {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: #e5e5e5;
    overflow: hidden;
  }
  .progress {
    height: 8px;
    border-radius: 4px;
    background-color: #0065df;
  }
  .progress-text {
    font-size: 12px;
    color: #666;
    margin-left: 14px;
  }
}
//tab切换 title
::v-deep .tab-titles {
  width: 100%;
  background-color: #fff;
  .tab-title {
    width: 25%;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    font-weight: bold;
    color: #bbb;
    text-align: center;
    float: left;
    &.active {
      color: #0065df;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 60px;
        height: 2px;
        background-color: #0065df;
        border-radius: 7px;
        position: absolute;
        bottom: -0.5px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
//搜索讲师通用样式
::v-deep .teacher-list-style {
  padding: 18px;
  overflow: hidden;
  .teacher-info {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .teacher-img {
    width: 90px;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
    background: url(~@/assets/m/head-default2.png) no-repeat center center;
    background-size: 100%;
    border: 0;
  }
  .teacher-content {
    width: 204px;
  }
  .teacher-title {
    display: flex;
    align-items: center;
  }
  .teacher-name {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }
  .teacher-level {
    padding: 4px 8px;
    background-color: #fdf3dc;
    border-radius: 3px;
    font-size: 10px;
    color: #eda220;
  }
  .teacher-introduce {
    height: 36px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .teacher-btn {
    width: 85px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    background-color: #0065df;
    font-size: 14px;
    color: #fff;
    float: right;
    margin-top: 26px;
  }
}
::v-deep .page-bottom-img {
  display: block;
  width: 144px;
  margin: 40px auto 20px;
}
</style>
