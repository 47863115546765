<template>
  <!-- 登录弹窗 -->
  <div class="tip">
    <div class="tip-content">
      <div class="tip-title">提示</div>
      <div class="tip-text">{{text}}</div>
      <div class="btns" v-if="showBtns">
        <button class="button blue" @click="closeTip">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: 'tipModal',
  props: {
    text: String,
    showBtns: Boolean,
    showTime: Number,
    fun: Function,
    confirmFun: Function
  },
  mounted(){
    if(!this.showBtns){
      let _this = this;
      setTimeout(() => {
        _this.CloseTipModal();
        if(_this.fun){
          _this.fun();
        }
      }, 2000);
    }
  },
  methods: {
    ...mapMutations(['CloseTipModal']), //tip弹窗
    //关闭弹窗
    closeTip(){
      this.CloseTipModal();
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.tip{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  .tip-content{
    width: 200px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
  }
  .tip-title{
    font-size: 20px;
    color: #333;
  }
  .tip-text{
    padding-top: 20px;
    font-size: 14px;
  }
  .btns{
    padding-top: 10px;
    .button{
      float: right;
    }
  }
}
</style>
