<template>
  <div class="cont_wrap">
    <div class="agreement_box">
      <div class="close" @click="closeAgree">
        <img src="@/assets/pc/close_ico.png" alt="" />
      </div>
      <div class="conten_text">
        <h1>《隐私政策、用户协议》</h1>
        <div class="text_info">
          本政策仅适用于中工科创文化传媒（北京）有限公司（以下简称中工云课堂）的产品或服务。
          <br />
          最近更新日期:2022年3月11日。 <br />
          您有权选择关闭网页或退出浏览器以拒绝接受本次隐私政策的更新，不再使用我们的产品和服务。
          <br />
          如果您有任何疑问、意见或建议,请通过以下联系方式与我们联系: <br />
          电 话:182182828 <br />

          本政策将帮助您了解以下内容: <br />
          一、我们如何收集和使用您的个人信息 <br />
          二、我们如何使用Cookie和同类技术 <br />
          三、我们如何共享、转让、公开披露您的个人信息 <br />
          四、我们如何保护您的个人信息 <br />
          五、您的权利及责任 <br />
          六、您的个人信息如何在全球范围转移 <br />
          七、本政策如何更新 <br />
          八、如何联系我们 <br />
          中工云课堂深知个人信息对您的重要性,并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任,恪守以下原则,保护您的个人信息:权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时,
          中工云课堂承诺,我们将按业界成熟的安全标准,采取相应的安全保护措施来保护您的个人信息。请在使用我们的产品(或服务)前,仔细阅读并了解本《隐私政策》，以便做出适当的选择。
          <br />
          <h2>一、我们如何收集和使用您的个人信息</h2>
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br />
          中工云课堂仅会出于本政策所述的以下目的,收集和使用您的个人信息:<br />
          1.核心业务功能，如果您不提供或同意我们收集、使用您的这些个人信息，将导致本产品（或服务）无法正常运行，我们将无法为你提供服务。<br />
          核心业务功能一：注册成为用户<br />
          为完成创建账号,您需提供以下真实信息:手机号，用于完成奥鹏账号的生成。<br />
          核心业务功能二：开展数据分析和研究,改善我们的产品或服务<br />
          我们会收集您的账号名、cookie、IP信息，记录您的账号在登录时使用的设备标识及登录地址，以便于我们分析您的账号安全使用情况，如发现异常设备登录、异地登录等异常行为。<br />
          2.附加业务功能，如果您不提供或同意我们收集、使用您的这些个人信息将导致导致该功能无法实现，但是不影响您使用本产品（或服务）的核心业务功能。<br />
          当我们要将信息用于本政策未载明的其他用途时,会事先征求您的同意。<br />
          当我们要将基于特定目的收集而来的信息用于其他目的时,会事先征求您的同意。<br />
          <h2>二、我们如何使用Cookie和同类技术</h2>
          (一) Cookie <br />
          为确保网站正常运转,我们可能会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie,网站能够存储您的偏好课程或课件等数据。我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情,请参见<a
            href="http://AboutCookies.org"
            target="_blank"
            rel="noopener noreferrer"
            >AboutCookies.org</a
          >。您可以清除计算机上保存的所有Cookie,大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做,则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置,请访问以下链接:
          <a
            href="https://support.microsoft.com/en-us"
            target="_blank"
            rel="noopener noreferrer"
            >&lt;InternetExplorer&gt;</a
          >、<a
            href="https://support.google.com/chrome/?p=help&ctx=settings#topic=7438008"
            target="_blank"
            rel="noopener noreferrer"
            >&lt;GoogleChrome&gt;</a
          >、<a
            href="https://support.mozilla.org/zh-CN/products/firefox?as=u&utm_source=inproduct"
            target="_blank"
            rel="noopener noreferrer"
            >&lt;MozillaFirefox&gt;</a
          >、<a
            href="https://support.apple.com/zh-cn/safari"
            target="_blank"
            rel="noopener noreferrer"
            >&lt;Safari&gt;</a
          >和<a
            href="https://help.opera.com/zh/zh-cn"
            target="_blank"
            rel="noopener noreferrer"
            >&lt;Opera&gt;</a
          >
          <br />
          (二) DoNotTrack(请勿追踪) <br />
          很多网络浏览器均设有DoNotTrack功能,该功能可向网站发布DoNotTrack请求。目前,主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了DoNotTrack,那么我们的所有网站都会尊重您的选择。

          <br />
          <h2>三、 我们如何共享、转让、公开披露您的个人信息</h2>
          (一) 共享<br />
          我们不会与中工云课堂以外的任何公司、组织和个人分享您的个人信息,但以下情况除外:<br />
          1.在获取明确同意的情况下共享:获得您的明确同意后,我们会与其他方共享您的个人信息。<br />
          2.我们可能会根据法律法规规定,或按政府主管部门的强制性要求,对外共享您的个人信息。<br />
          3.与授权合作伙伴共享:仅为实现本政策中声明的目的,我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息,以提供必要的、更好的客户服务和用户体验。
          例如,我们需要向合作院校、机构共享您的个人信息，以便合作院校、机构可以为您提供相应的教学服务、助学服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息,
          并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。<br />
          对我们与之共享个人信息的公司、组织和个人,我们会与其签署严格的保密协定,要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br />
          (二) 转让<br />
          我们不会将您的个人信息转让给任何公司、组织和个人,但以下情况除外:<br />
          1.在获取明确同意的情况下转让:获得您的明确同意后,我们会向其他方转让您的个人信息;<br />
          2.在涉及合并、收购、资产转让或破产清算时,如涉及个人信息转让,我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束,否则我们将要求该公司、组织重新向您征求授权同意。<br />
          (三) 公开披露<br />
          我们仅会在以下情况下,公开披露您的个人信息:<br />
          1.获得您明确同意后；<br />
          2.对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的信息；<br />
          3.基于法律的披露:在法律、法律程序、诉讼或政府主管部门强制性要求的情况下,在下列情形中，我们可能会公开披露您的个人信息而无需征得您的授权同意：<br />
          a.与国家安全、国防安全直接相关的；<br />
          b.与公共安全、公共卫生、重大公共利益直接相关的；<br />
          c.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br />
          d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />
          e.您自行向社会公众公开的个人信息；<br />
          f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
          g. 法律法规规定的其他情形。<br />

          四、 我们如何保护您的个人信息<br />
          (一)
          我们已使用符合业界标准的安全防护措施保护您提供的个人信息,防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
          我们会采取一切合理可行的措施,保护您的个人信息。例如,在您的浏览器与“服务”之间交换数据(如信用卡信息)时受SSL
          加密保护;
          我们同时对网站提供https安全浏览方式;我们会使用加密技术确保数据的保密性;我们会使用受信赖的保护机制防止数据遭到恶意攻击;
          我们会部署访问控制机制,确保只有授权人员才可访问个人信息;以及我们会举办安全和隐私保护培训课程,加强员工对于保护个人信息重要性的认识。<br />
          (二) 我们已经取得了以下认证:ISO27001信息安全管理体系、等级保护。<br />
          (三)
          我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。<br />
          (四)
          我们会采取一切合理可行的措施,确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息,除非需要延长保留期或受到法律的允许。<br />
          (五)
          互联网并非绝对安全的环境,而且即时通讯、及与其他用户的交流方式并未加密,我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码,协助我们保证您的账号安全。<br />
          (六)
          我们将定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。<br />
          (七)
          互联网环境并非百分之百安全,我们将尽力确保或担保您发送给我们的任何信息的安全性。尽管已经采取了合理有效的措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。<br />
          (八)
          在不幸发生个人信息安全事件后,我们将按照法律法规的要求,及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您,难以逐一告知个人信息主体时,我们会采取合理、有效的方式发布公告。同时,我们还将按照监管部门要求,主动上报个人信息安全事件的处置情况。<br />

          <h2>五、 您的权利及责任</h2>
          1.按照中国相关的法律、法规、标准,以及其他国家、地区的通行做法,我们保障您对自己的个人信息行使以下权利:<br />
          (一)访问您的个人信息<br />
          您有权访问您的个人信息,法律法规规定的例外情况除外。如果您想行使数据访问权,可以通过以下方式自行访问:<br />
          PC端登录<a
            href="https://kskweb.kjcxchina.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://kskweb.kjcxchina.com</a
          >
          ,在“个人资料”处可以查看个人信息<br />
          (二)更正您的个人信息<br />
          可以通过以下方式自行访问:<br />
          PC端登录
          <a
            href="https://kskweb.kjcxchina.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://kskweb.kjcxchina.com</a
          >
          ,在“个人资料”处可以查看个人信息<br />
          (三)删除您的个人信息<br />
          在以下情形中,您可以向我们提出删除个人信息的请求:<br />
          1. 如果我们处理个人信息的行为违反法律法规;<br />
          2. 如果我们收集、使用您的个人信息,却未征得您的同意;<br />
          3. 如果我们处理个人信息的行为违反了与您的约定;<br />
          若我们决定响应您的删除请求,我们还将同时通知从我们获得您的个人信息的实体,要求其及时删除,除非法律法规另有规定,或这些实体获得您的独立授权。
          当您从我们的服务中删除信息后,我们可能不会立即备份系统中删除相应的信息,但会在备份更新时删除这些信息。<br />
          (四)个人信息主体获取个人信息副本<br />
          在技术可行的前提下,例如数据接口匹配,我们还可按您的要求,直接将您的个人信息副本传输给您指定的第三方。<br />
          (五)响应您的上述请求<br />
          为保障安全,您可能需要提供书面请求,或以其他方式证明您的身份。我们可能会先要求您验证自己的身份,然后再处理您的请求。<br />
          我们将在30天内做出答复。如您不满意,还可以通过以下途径投诉:<br />
          电 话: <br />
          对于您合理的请求,我们原则上不收取费用,但对多次重复、超出合理限度的请求,我们将视情收取一定成本费用。
          对于那些无端重复、需要过多技术手段(例如,需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如,涉及备份磁带上存放的信息)的请求,我们可能会予以拒绝。<br />
          在以下情形中,按照法律法规要求,我们将无法响应您的请求:<br />
          1.与国家安全、国防安全直接相关的;<br />
          2.与公共安全、公共卫生、重大公共利益直接相关的;<br />
          3.与犯罪侦查、起诉、审判和判决执行等直接相关的;<br />
          4.有充分证据表明您存在主观恶意或滥用权利的;<br />
          5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。<br />
          6.涉及商业秘密的。<br />
          2.您的责任<br />
          在使用本产品（或服务）的过程中，您有责任保管好您的个人信息<br />
          （一）不将您的个人信息，如您的个人账号及登录密码主动透露给与本产品（或服务）无关的人员或机构。
          <br />
          （二）
          不将您的权责内获取的他人个人信息用于超出服务合同约定以外的用途；在未经个人信息所有者同意的前提下不得复制、转发、传递给其他人或机构。<br />
          （三）因您违反了您的责任，造成了您或他人的损失及相应的法律法规及合同风险，您将自行承担相应责任，我们有权不再提供上述“您的权力”的支持。<br />

          <h2>六、 您的个人信息如何在全球范围转移</h2>
          我们收集的您的信息都在中华人民共和国境内收集和产生的个人信息,都存储在中华人民共和国境内，没有在全球范围转移。<br />

          <h2>七、 本政策如何更新</h2>
          我们的用户协议及隐私政策可能变更。<br />
          未经您明确同意,我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。<br />
          对于重大变更,我们还会提供更为显著的通知(包括对于某些服务,比如：登录时弹窗，说明隐私政策的具体变更内容)。<br />
          本政策所指的重大变更包括但不限于:<br />
          1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。<br />
          2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。<br />
          3.个人信息共享、转让或公开披露的主要对象发生变化。<br />
          4.您参与个人信息处理方面的权利及其行使方式发生重大变化。<br />
          5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。<br />
          6.个人信息安全影响评估报告表明存在高风险时。<br />
          我们还会将本政策的旧版本存档,供您查阅。<br />

          <h2>八、 如何联系我们</h2>
          如果您对本隐私政策有任何疑问、意见或建议,通过以下方式与我们联系:<br />
          电子邮件： <br />
          电 话:4008106736<br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pc-agreement",
  methods: {
    closeAgree() {
      if(this.$listeners['agreeBtn']){
        this.$emit("agreeBtn", "false");
      }else{
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cont_wrap {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9999;
  .agreement_box::-webkit-scrollbar {
    display: none;
  }
  .agreement_box {
    width: 900px;
    height: 95vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
    margin: 20px auto 0;
    position: relative;
    // 关闭按钮
    .close {
      width: 100%;
      height: 30px;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      padding-right: 10px;
      //   position: fixed;
      width: 900px;
      position: fixed;
      right: 50%;
      transform: translateX(50%);
      padding: 10px 10px 30px 0;
      top: 0;
      box-shadow: 0px 0px 10px #ccc;
      border-radius: 10px 10px 0 0;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .conten_text {
      padding: 0 30px 40px;
      margin-top: 50px;
      h1 {
        text-align: center;
        padding-bottom: 10px;
        font-size: 22px;
      }
      h2 {
        font-size: 18px;
      }
      .text_info {
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
}
</style>