<template>
  <div class="header">
    <div class="nav_list">
      <div class="nav_cont flex">
        <div class="flex leftCont_box">
          <div class="logo" @click="$router.push('/index')">
            <!---->
            <img v-if="siteInfo.site_logo" :src="siteInfo.site_logo" alt="" />
             <img v-else src="@/assets/pc/newIndex/logo.png" alt="" /> 
          </div>
          <ul class="menu_list flex">
            <li v-for="item in navList" :key="item.name">
              <template v-if="linkState && item.url=='/index'">
                <a :href="item.url" target="_blank" v-if="item.url.includes('http')">{{ item.name }}</a>
               <router-link class="activeMenu" :to="item.url" v-else>{{ item.name }}</router-link>
              </template>
              <template v-else>
                <a :href="item.url" target="_blank" v-if="item.url.includes('http')">{{ item.name }}</a>
                <router-link active-class="activeMenu" :to="item.url" v-else>{{ item.name }}</router-link>
              </template>
              
            </li>
          </ul>
        </div>
        <div class="flex">
          <div class="search flex">
            <img
              src="@/assets/pc/user/search_icon.png"
              alt=""
              @click="searchBtn"
              id="searchIcon"
              
            />
            <input type="text"  v-model="seacherText"  @keydown="handleKeyDown" placeholder="搜索">
          </div>
          <a href="https://www.wjx.cn/vm/OXIWqro.aspx#" target="_blank" rel="noopener noreferrer">
            <div class="cont_btn_com flex">
              <img src="@/assets/pc/newIndex/head_icon1.png" alt="">
              <p>企业免费试用</p>
            </div>
          </a>
          <div class="per_info flex" v-if="userData">
            <p class="avatar_img">
              <img v-if="userData.avatar" :src="userData.avatar" alt="" />
              <img v-else src="@/assets/pc/per.png" alt="" />
            </p>
            <!-- <p class="text" v-if="userData.nickname">{{ userData.nickname }}</p>
            <p class="text" v-else-if="userData.name">{{ userData.name }}</p>
            <p class="text" v-else-if="userData.phone">
              {{
                userData.phone.slice(0, 4) +
                "****" +
                userData.phone.slice(7, 11)
              }}
            </p> -->
            <div class="sign_out">
              <div class="sign_cont">
                <ul>
                  <li class="flex" @click="changMenu('/learnCenter/learn_record')">
                    <img class="icon1" src="@/assets/pc/user/per/learn_1.png" alt="" />
                    <img class="icon2" src="@/assets/pc/user/per/learn_2.png" alt="" />
                    <span>学习中心</span>
                  </li>
                  <li class="flex" @click="changMenu('/userCenter/personal_info')">
                    <img class="icon1" src="@/assets/pc/user/per/per_1.png" alt="" />
                    <img class="icon2" src="@/assets/pc/user/per/per_2.png" alt="" />
                    <span>个人中心</span>
                  </li>
                  <!-- <li class="flex" @click="changMenu('/userCenter/my_order')">
                    <img src="@/assets/pc/login/order_icon.png" alt="" />
                    <span>我的订单</span>
                  </li>
                  <li class="flex" @click="changMenu('4')">
                    <img src="@/assets/pc/login/icon3.png" alt="" />
                    <span>收藏关注</span>
                  </li> -->
                </ul>
                <div class="btn flex">
                  <!-- <p @click="changMenu('/userCenter/feedback_info')">我要反馈</p> -->
                  <p @click="signOut()" class="flex"><img src="@/assets/pc/user/per/out_icon.png" alt="">退出账号</p>
                </div>
              </div>
            </div>
          </div>
          <div class="login flex" v-else>
            <p @click="loginBtn">登录 /</p>
            <!-- <p class="line"></p> -->
            <p><router-link to="/register_way">注册</router-link></p>
          </div>
        </div>
      </div>
      <div class="search_box" v-if="seacherState" id="moreBox">
        <div class="search_input">
          <div class="input_text flex">
            <input
              type="text"
              placeholder="请输入搜索内容"
              v-model="seacherText"
            />

            <img
              @click="searchBtn"
              src="@/assets/pc/user/search_icon.png"
              alt=""
            />
          </div>
          <div class="hot_word" v-if="keywordList">
            <div style="line-height: 30px;">热搜关键词：</div>
            <!-- <p
              v-for="(item, index) in keywordList"
              :key="index"
              @click="keywordSearch(item)"
            >
              {{ item }}
            </p> -->
            <div class="list">
              <p @click="keywordSearch('西门子')">西门子</p>
            <p @click="keywordSearch('安全生产')">安全生产</p>
            <p @click="keywordSearch('班组管理')">班组管理</p>
            <p @click="keywordSearch('精益生产')">精益生产</p>
            <p @click="keywordSearch('前沿技术')">前沿技术</p>
            <p @click="keywordSearch('创新工作室')">创新工作室</p>
            </div>
            <!-- <span>机床装调维修</span><span>机床装调维修</span> -->
            <!-- <span class="cursor" @click="keywordSearch">{{ keywordText }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import loginWay from '../common/login_way.vue'
// import Tip from "../tip.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "pc-header",

  components: {
    // loginWay,
    // Tip,
  },
  computed: {
    ...mapState(["tipModal", "loginModal", "userInfo"]),
  },
  watch: {
    $route: {
      handler(newVal) {
      
        if(newVal&&newVal.meta.index==1){
            this.linkState=true
        }else{
            this.linkState = false
        }
       
      },
      immediate: true,
    },
  },
  data() {
    return {
      linkState:false,
      navList: [],
      maskState: false,
      logInfo: {
        //登录信息
        username: "", //手机号
        password: "", //密码
        type: 1, //1.个人登录 2.企业登录
        agree: true, //同意隐私条款
      },
      loginIndex: "1",
      userData: {},
      seacherText: "",
      seacherState: false,
      keywordList: "",
      siteInfo:'',
    };
  },
  created() {
    document.addEventListener("click", (e) => {
      let box = document.getElementById("moreBox");
      let box1 = document.getElementById("searchIcon");
      if (box && box1) {
        if (!box.contains(e.target) && !box1.contains(e.target)) {
          this.seacherState = false;
        }
      }
    });
  },
  mounted() {
    this.getNav(); //导航菜单
    this.userData = JSON.parse(localStorage.getItem("userInfo"));
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
    this.getKeyWord();
    var _this = this;

    document.onkeydown = function (event) {
      //在全局中绑定按下事件
      var e = event || window.e;
      var keyCode = e.keyCode || e.which;
      if (keyCode == 13 && _this.seacherState && _this.seacherText) {
        _this.searchBtn();
      }
    };
    
  },
  methods: {
    ...mapMutations([
      "ShowLoginModal",
      "CloseLoginModal",
      "GetUserInfo",
      "ClearUserInfo",
    ]), //登录弹窗
    // 退出登录
    signOut() {
      this.ClearUserInfo();
      location.reload(); //刷新页面
     
    },
    //获取导航菜单
    getNav(){
      // 导航
      this.$axios.get(`/v1/px/home/menu`).then((res) => {
        if (res.code == 0) {
          this.navList = res.data;
        }
      });
    },
    goUserInfo() {
      this.$router.push("/userCenter/personal_info");
    },
    loginBtn() {
      this.ShowLoginModal();
    },
    // 注册个人账号
    postBtn(num) {
      this.$router.push({ path: "/register_info", query: { showNum: num } });
      this.maskState = false;
    },
    changMenu(item) {
      if (item != 4) {
        this.$router.push(item);
      } else {
        this.$router.push("/learnCenter/learn_record?id=" + 2);
      }
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    hide() {
      this.maskState = false;
    },
    getKeyWord() {
      this.$axios.get(`/v1/px/home/keyword`).then((res) => {
        if (res.code == 0) {
          this.keywordList = res.data;
        }
      });
    },

    // 搜索
    searchBtn() {
      this.seacherState = false;
      this.$router.push(
        "/teacher/teacher_search?seacherText=" + this.seacherText
      );
      this.seacherText = "";
    },
    handleKeyDown(event){
      if (event.key === 'Enter') {
        // 处理Enter键的逻辑
        this.seacherState = false;
        this.$router.push(
          "/teacher/teacher_search?seacherText=" + this.seacherText
        );
        this.seacherText = "";
      }
    },
    keywordSearch(item) {
      this.seacherState = false;

      this.$router.push("/teacher/teacher_search?seacherText=" + item);
    },
     goLearnCenter(){
      
      if(this.userData){
       this.$router.push('/learnCenter/learn_record')
      }else{
        this.ShowLoginModal();
      }
      
    }
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 9;
  .box_top {
    background: #f6f6f6;
    height: 36px;
    .top_btn {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      justify-content: space-between;
      .left_select {
        h2 {
          font-size: 14px;
          color: #000000;
          margin-right: 24px;
          font-weight: normal;
        }
        .select {
          margin-right: 24px;
          position: relative;
          p.flex {
            cursor: pointer;
            span {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
            }
            img {
              transition: all 1s ease;
              transform: rotate(90deg);
            }
          }
          .show_code {
            position: absolute;
            left: -50%;
            top: 25px;
            height: 0;
            z-index: 5;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
            img {
              height: 100%;
            }
          }
          select {
            border: none;
            outline: none;
            background: none;
            color: #999999;
          }
        }
        .select:hover {
          .show_code {
            transition: all 1s ease;
            height: auto;
            img{
              height: 170px;
            }
          }
          p.flex {
            img {
              transition: all 1s ease;
              transform: rotate(-90deg);
            }
          }
        }
        // .select:hover {

        //   // transform: rotate(225deg);
        // }
      }
      .right_list {
        li {
          margin-right: 32px;
          font-size: 14px;
          color: #999999;
          cursor: pointer;
          a {
            color: #999;
          }
        }
        li:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .nav_list {
    // width: 1200px;
    margin: 0 auto;
    height: 74px;
    justify-content: space-between;
    position: relative;
    .nav_cont {
      width: 1200px;
      margin: 0 auto;
      height: 74px;
      justify-content: space-between;
    }
    .leftCont_box{
      flex: 1;
      margin-right: 22px;
    }
    .logo {
      width:130px;
      height: 40px;
      img{
         width:130px;
         height: auto;
        display: block;
      }
    }
    .menu_list {
      margin-left: 29px;
      flex: 1;
      justify-content: space-between;
      li {
        // margin-right: 32px;
        
        font-size: 16px;
        a {
          color: #333333;
        }
        .activeMenu::before {
          content: "";
          width: 20px;
          height: 3px;
          display: block;
          margin: 0 auto;
          margin-top: 4px;
          background-color: #fff;
          border-radius: 30px;
        }
        .activeMenu {
          color: #0065df;
          // padding-bottom: 5px;
          // border-bottom: 2px solid #0065df;
        }
        .activeMenu::after {
          content: "";
          width: 20px;
          height: 3px;
          display: block;
          margin: 0 auto;
          margin-top: 4px;
          background-color: #0065df;
          border-radius: 30px;
        }
      }
    }
    .search {
      margin-right: 15px;
      width: 170px;
      height: 38px;
      background: #F3F5F6;
      border-radius: 38px;
      box-sizing: border-box;
      padding: 0 12px;
      img {
        width: 16px;
      }
      input{
        width: 80%;
        margin-left: 10px;
        background: none;
        outline: none;
      }
      p {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      a {
        color: #333;
      }
      .activeMenu {
        color: #0065df;
          
        
      }
    }
    .cont_btn_com{
      img{
        width: 16px;
        height: 16px;
        display: block;
        margin: 0px 2px 0 0;
        
      }
      p{
        font-size: 14px;
        color: #0065DF;
      }
    }

    .per_info {
      margin-left: 33px;
      position: relative;
      p.avatar_img {
        width: 35px;
        height: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          display: block;
        }
      }
      p.text {
        font-size: 14px;
        margin-left: 8px;
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      // 退出登录
      .sign_out {
        display: none;
        position: absolute;
        right: -18px;
        top: 35px;
        z-index: 9;
        width: 321px;
        min-height: 243px;
        background: url('@/assets/pc/user/per/per_bgm.png') no-repeat;
        background-size: 100% 100%;
        box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.05);
        border-radius: 5px 5px 5px 5px;
        box-sizing: border-box;
        padding: 20px 20px 12px 20px;
        ul {
          // border-bottom: 1px solid #f5f5f5;
          padding-bottom: 6px;
          padding-top: 42px;
          li:first-child {
            margin-bottom: 14px;
          }
          li {
            width: 240px;
            height: 44px;
            background: #F8F8F8;
            justify-content: center;
            cursor: pointer;
            margin: 0 auto;
            img{
              width: 18px;
              height: 18px;
            }
            .icon2{
              display: none;
            }
            span {
              margin-left: 14px;
              font-size: 14px;
              color: #333333;
            }
          }
          li:hover{
            .icon1{
              display: none;
            }
            .icon2{
              display: block;
            }
            span{
              color: #0065DF;
            }
          }

        }
        div.btn {
          margin-top: 14px;
          justify-content: flex-end;
          p {
            img{
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            cursor: pointer;
            font-size: 14px;
            color: #999999;
          }
        }
      }
    }
    .per_info:hover {
      .sign_out {
        display: block;
      }
    }
    // 搜索弹出框
    // 搜索框
    .search_box {
      position: absolute;
      left: 0;
      top: 72px;
      background: #fff;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.07);
      width: 100%;
      min-height: 80px;
      z-index: 30;
      .search_input {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        div.input_text {
          margin: 18px auto 0;
          width: 595px;
          height: 44px;
          background: #eaeaea;
          border-radius: 54px 54px 54px 54px;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 15px;
          input {
            background: none;
            height: 80%;
            width: 70%;
            border: none;
            outline: none;
          }
        }
        .hot_word {
          margin: 18px auto 0;
          width: 595px;
          padding-bottom: 30px;
          display: flex;
          color: #666;
          font-size: 14px;
          >div.list{
            display: flex;
            flex-wrap: wrap;
            flex: 1;
          }
          p {
            margin-right: 5px;
            cursor: pointer;
            // background: #e6e6e6;
            border-radius: 3px 3px 3px 3px;
            padding: 5px 10px;
          }
          p:hover {
            // background: rgba(255, 195, 11, 0.18);
            color: #ff8a1f;
          }
        }
      }
    }
  }
  .login {
    // width: 117px;
    height: 32px;
    // background: #0065df;
    // border-radius: 41px 41px 41px 41px;
    justify-content: center;
    align-items: center;
    margin-left: 26px;
    p {
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
    p.line {
      height: 12px;
      width: 1px;
      background: #fff;
      margin: 4px 5px 0;
      cursor: pointer;
    }
    a {
      color: #333;
    }
  }
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
</style>
