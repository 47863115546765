<template>
  <div class="header">
    <div class="mask_login">
      <!-- 个人登录 -->
      <div class="login_popover" v-if="loginIndex == '1'">
        <div class="close" @click="hide">
          <img src="@/assets/pc/close.png" alt="" />
        </div>
        <div class="top_title flex">
          <p class="tips">欢迎登录中工云课堂</p>
          <div class="right_change flex">
            <p @click="companyLogin"><a href="http://e.npx.kskstudy.com/" target="_blank" rel="noopener noreferrer">企业登录</a> </p>
            <img src="@/assets/pc/login/change_icon.png" alt="" />
          </div>
        </div>
        <div class="form">
          <p class="title">账号登录</p>
          <ul>
            <li>
              <input
                type="text"
                oninput = "value=value.replace(/[^\d]/g,'')"
                class="redLine"
                placeholder="手机号/邮箱/用户名"
                v-model="logInfo.username"
                maxlength="11"
              />
              <div class="tips" v-if="usernameErr">{{ usernameErr }}</div>
            </li>
            <li class="flex passWord">
              <input
                :type="passwordState === '0' ? 'password' : 'text'"
                placeholder="请输入密码"
                v-model="logInfo.password"
                maxlength="16"
              />
              <img
                v-if="passwordState == '0'"
                @click="passwordState = '1'"
                src="@/assets/pc/user/eye_off.png"
                alt=""
              />
              <img
                v-if="passwordState == '1'"
                @click="passwordState = '0'"
                src="@/assets/pc/user/eye_open.png"
                alt=""
              />
              <div class="tips" v-if="passwordErr">{{ passwordErr }}</div>
            </li>
          </ul>
          <div class="chooseMode flex">
            <p @click="postBtn('1')">注册个人账户</p>
            <p @click="forgetPass">忘记密码？</p>
          </div>
        </div>
        <div
          class="sign cursor"
          :class="logInfo.password && logInfo.username ? 'signLogin' : 'sign'"
          @click="loginBtn"
        >
          登录
        </div>
        <div class="checkbox flex">
          <input type="checkbox" v-model="logInfo.agree" class="box_check" />
          登录即代表同意 <span  @click="agreementState = true">《用户服务协议》</span>和<span  @click="agreementState = true"
            >《隐私协议》</span
          >
        </div>

        <!-- <div class="weixin_login flex cursor" @click="weixinBtn()">
          <img src="@/assets/pc/weixin.png" alt="" />
          <p>微信扫码登录</p>
        </div> -->
      </div>
      <!-- 企业登录 -->
      <div class="login_popover" v-if="loginIndex == '2'">
        <div class="top_title flex">
          <p class="tips">欢迎登录中工云课堂</p>
          <div class="right_change flex">
            <p @click="loginIndex = '1'">个人登录</p>
            <img src="@/assets/pc/login/change_icon.png" alt="" />
          </div>
        </div>
        <div class="form">
          <p class="title">账号登录</p>
          <ul>
            <li>
              <input
                type="text"
                class="redLine"
                placeholder="请输入您的企业账号"
                v-model="logInfo.username"
              />
              <div class="tips">请输入您的企业账号</div>
            </li>
            <li>
              <input
                type="text"
                placeholder="请输入密码"
                v-model="logInfo.password"
              />
              <div class="tips">请输入密码</div>
            </li>
          </ul>
          <div class="chooseMode flex">
            <p @click="postBtn('2')">注册企业账户</p>
            <p>忘记密码？</p>
          </div>
        </div>
        <div class="sign cursor" @click="loginBtn">登录</div>
        <div class="checkbox flex">
          <input type="checkbox" v-model="logInfo.agree" class="box_check" />
          登录即代表同意 <span>《用户服务协议》</span>和<span
            >《隐私协议》</span
          >
        </div>

        <div class="weixin_login flex cursor" @click="weixinBtn()">
          <img src="@/assets/pc/weixin.png" alt="" />
          <p>微信扫码登录</p>
        </div>
      </div>
      <!-- 微信登录 -->
      <div class="login_popover weixin_box" v-if="loginIndex == '3'">
        <div class="close" @click="hide">
          <img src="@/assets/pc/close.png" alt="" />
        </div>
        <div class="top_title flex">
          <p class="tips">微信登录</p>
          <div class="right_change flex">
            <p @click="companyLogin"><a href="http://e.npx.kskstudy.com/" target="_blank" rel="noopener noreferrer">企业登录</a> </p>
            <img src="@/assets/pc/login/change_icon.png" alt="" />
          </div>
        </div>
        <div class="cont_text">
          <p class="title_tips">请使用微信扫描二维码登录</p>
          <p class="img_code">
            <img :src="qrInfo.qr_code" alt="" />
          </p>
          <!-- <p class="title_tips cursor" @click="accountLogin">账户登录</p> -->
          <div class="deep_text" >
            登录即代表已同意 <span @click="agreementState = true">《用户服务协议》</span>和<span @click="agreementState = true"
              >《隐私协议》</span
            >
          </div>
          <div class="btn_text flex">
            <span @click="accountLogin">账户登录</span>
            <p @click="goRegister">没有账号？</p>
          </div>
        </div>
      </div>
      <!-- 绑定手机号 -->
      <div class="login_popover" v-if="loginIndex == '4'">
        <div class="close" @click="hide">
          <img src="@/assets/pc/close.png" alt="" />
        </div>
        <div class="top_title flex">
          <p class="tips" style="width: 100%; text-align: center">绑定手机号</p>
          <!-- <div class="right_change flex">
            <p @click="loginIndex = '1'">个人登录</p>
            <img src="@/assets/pc/login/change_icon.png" alt="" />
          </div> -->
        </div>
        <div class="get_code">
          <p class="tips_text">
            应国家网络安全需要，需要通过绑定手机号来完善实名认证机制
          </p>
          <ul>
            <li>
              <div class="input flex">
                <input
                  :class="bindPhoneNumErr ? 'inputLine' : ''"
                  type="text"
                  placeholder="请输入联系人手机号"
                  v-model="bindPhoneNum"
                  maxlength="11"
                />
              </div>
              <transition name="fade" transiton="fade">
                <div class="tips" v-if="bindPhoneNumErr">
                  {{ bindPhoneNumErr }}
                </div>
              </transition>
            </li>
            <li>
              <div class="flex">
                <input
                  v-model="bindCode"
                  :class="bindCodeErr ? 'inputLine' : ''"
                  type="text"
                  placeholder="请输入验证码"
                />
                <p
                  :class="timer ? 'btnTime btn cursor' : 'btn cursor'"
                  @click="countDown"
                >
                  {{ getCodeText }}
                </p>
              </div>
              <transition name="fade" transiton="fade">
                <div class="tips" v-if="bindCodeErr">
                  {{ bindCodeErr }}
                </div>
              </transition>
            </li>
          </ul>
          <div class="deepBtn cursor" @click="bindPhone">立即绑定</div>
        </div>
      </div>
      <div class="login_popover" v-if="loginIndex == '5'">
        <!-- <div class="close" @click="hide">
          <img src="@/assets/pc/close.png" alt="" />
        </div> -->
        <div class="login_success">
          <p class="img"><img src="@/assets/pc/login/ok.png" alt="" /></p>
          <p class="tips">登录成功</p>
          <h2>欢迎加入中工云课堂！</h2>
          <p class="tims">{{timeNum}}s后自动返回首页</p>
        </div>
      </div>
    </div>
     <div class="agree_box" v-if="agreementState">
      <Agreement @agreeBtn="agreeBtn($event)" />
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { ApiUrl } from "@/utils/util";
import Agreement from "@/views/pc/components/common/agreement.vue";

import axios from "axios";
export default {
  name: "pc-header",

  components: {
    Agreement
  },
  //   props: ["maskState1"],
  props: {
    // maskState1:Boolean
  },
  watch: {
    // maskState1() {
    //   this.maskState = true
    // },
  },
  data() {
    return {
      agreementState:false,
      maskState: false,
      logInfo: {
        //登录信息
        username: "", //手机号
        password: "", //密码
        type: 1, //1.个人登录 2.企业登录
        agree: true, //同意隐私条款
      },
      bindPhoneNum: "", //绑定手机号
      bindPhoneNumErr: "", //绑定手机号
      bindCode: "",
      bindCodeErr: "",
      getCodeText: "获取验证码",
      timer: "",
      usernameErr: "",
      passwordErr: "",
      loginIndex: "1",
      qrInfo: "",
      timerInterval: "",
      clickIndex: "1",
      open_id: "",
      passwordState: "0",
      goIndex: false,
      timeNum:'1'
    };
  },
  mounted() {
    this.ClearUserInfo();
    if (this.loginIndex == "3") {
      this.weixinBtn();
    }
    if (window.location.href.indexOf("register_way") != -1) {
      this.goIndex = true;
    } else {
      this.goIndex = false;
    }
  },
  methods: {
    ...mapMutations(["CloseLoginModal", "ShowTipModal", "ClearUserInfo"]), //登录弹窗
agreeBtn() {
      this.agreementState = false;
    },
    loginBtn() {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; 
      if (!this.logInfo.username) {
        this.usernameErr = "请输入手机号/邮箱/用户名";
      } else {
        this.usernameErr = "";
      }
      
      if (!this.logInfo.password) {
        this.passwordErr = "请输入密码";
      } else {
        this.passwordErr = "";
      }
      if (!reg_tel.test(this.logInfo.username)) {
        this.usernameErr = "请输入正确的手机号/邮箱/用户名";
        return
      } else {
        this.usernameErr = "";
      }
      // this.maskState = true;
      if (this.logInfo.username && this.logInfo.password) {
        this.$axios.post(`/v1/login/login`, this.logInfo).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));

            this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true}).then(() => {  //登录记录
              this.loginIndex = "5";
              let timerInterval=setInterval(()=>{
                this.timeNum--
                if(this.timeNum==0){
                  clearInterval(timerInterval)
                }
              },1000)
              setTimeout(() => {
                if (this.goIndex) {
                  this.$router.push("/index");
                  this.CloseLoginModal();
                } else {
                  location.reload(); //刷新页面
                  this.CloseLoginModal();
                }
              }, 1000);
            })
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 企业登录
    companyLogin() {
    },
    // 微信登录
    weixinBtn() {
      this.loginIndex = 3;

      axios({
        method: "get",
        url: "https://laokskapi.kskstudy.com/api/v1/getLoginQrCode",
        data: {},
        headers: {
          referer2: "npx.kskstudy.com",
        },
      }).then((res) => {
        this.qrInfo = res;
        this.timerInterval = setInterval(() => {
          this.getOpenId();
        }, 2000);
      });
    },
    // 账号登录切换
    accountLogin() {
      this.loginIndex = 1;
      clearInterval(this.timerInterval);
    },
    getOpenId() {
      this.$axios
        .post(`/v1/px/login/wxLogin`, { openid_key: this.qrInfo.qr_str })
        .then((res) => {
          // code=0 已绑定手机号，无需在绑定  2需要绑定手机号 1不管
          if (res.code == 0) {
            clearInterval(this.timerInterval);
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));

            this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true}).then(() => {  //登录记录
              this.loginIndex = 5;
              let timerInterval=setInterval(()=>{
                this.timeNum--
                if(this.timeNum==0){
                  clearInterval(timerInterval)
                }
              },1000)
              setTimeout(() => {
                if (this.goIndex) {
                  this.$router.push("/index");
                  this.CloseLoginModal();
                } else {
                  location.reload(); //刷新页面
                  this.CloseLoginModal();
                }
              }, 2500);
            })
          }
          if (res.code == 2) {
            clearInterval(this.timerInterval);

            this.loginIndex = 4;
            this.open_id = res.data.openid;
          }
        });
    },
    // 绑定手机号
    // 获取验证码
    countDown() {
      if (this.bindPhoneNum && this.clickIndex == "1") {
        this.$axios
          .post(`${ApiUrl}/common/sendSms`, {
            phone: this.bindPhoneNum,
            sms_code_use: "wxBindPhone",
          })
          .then((res) => {
            if (res.code == 0) {
              this.clickIndex = "2";
              let time = 60;
              this.getCodeText = time + "秒后再获取";
              this.timer = setInterval(() => {
                time--;
                if (time == 0) {
                  this.getCodeText = "获取验证码";
                  clearInterval(this.timer);
                  this.timer = "";
                  this.clickIndex = "1";
                } else {
                  this.getCodeText = time + "秒后再获取";
                }
              }, 1000);
            } else {
              this.bindCodeErr = res.msg;
            }
          });
      } else {
        this.bindPhoneNumErr = "请输入手机号";
      }
    },
    bindPhone() {
      this.$axios
        .post(`/v1/px/login/bindPhone`, {
          phone: this.bindPhoneNum,
          code: this.bindCode,
          open_id: this.open_id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.loginIndex = 5;
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            location.reload(); //刷新页面
            setTimeout(() => {
              this.CloseLoginModal();
            }, 1500);
          } else {
            this.$message.error(res.msg);
            // this.ShowTipModal({
            //   text: res.msg,
            // });
          }
        });
    },
    // 注册个人账号
    postBtn(num) {
      this.CloseLoginModal();

      this.$router.push({ path: "/register_info", query: { showNum: num } });
      this.maskState = false;
    },
    goRegister() {
      this.CloseLoginModal();
      this.$router.push("/register_way");
    },
    // 忘记密码
    forgetPass() {
      this.CloseLoginModal();
      this.$router.push("/changePassword");
    },
    hide() {
      clearInterval(this.timerInterval);
      this.CloseLoginModal();
      // this.$router.push("/index");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.header {
  // 登录弹出框
  .mask_login {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .close {
      width: 20px;
      position: absolute;
      position: absolute;
      left: 50%;
      top: -18px;
      z-index: -1;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
      }
    }
    .login_popover {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 390px;
      height: 498px;
      background: #ffffff;
      border-radius: 5px 5px 5px 5px;

      .top_title {
        width: 100%;
        height: 78px;
        background: linear-gradient(185deg, #1e84ff 0%, #0065df 100%);
        padding: 0 30px;
        justify-content: space-between;
        border-radius: 5px 5px 0 0;
        .tips {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
        .right_change {
          p ,a{
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
          img {
            width: 11px;
            height: 11px;
            margin-left: 7px;
          }
        }
      }
      .form {
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          margin-top: 24px;
          padding-left: 30px;
        }
        > div {
          padding: 0 30px;
        }
        ul {
          padding: 0 30px;
          li {
            position: relative;
            margin-top: 30px;
            input {
              width: 330px;
              height: 38px;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #f6f6f6;
              padding: 0 15px;
            }
            .tips {
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #e15416;
              // display: none;
              position: absolute;
              left: 0;
              bottom: -20px;
            }
          }
          li.passWord {
            input {
              padding: 0 50px 0 15px;
            }
            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
            }
          }
        }
        .chooseMode {
          justify-content: space-between;
          margin-top: 24px;
          cursor: pointer;
          p {
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .sign {
        width: 329px;
        height: 38px;
        background: #99c1f2;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        font-size: 14px;
        margin: 35px auto 0;
      }
      .signLogin {
        background: #0065df;
      }
      .checkbox {
        margin-left: 30px;
        margin-top: 15px;
        input {
          position: relative;
          background: url(@/assets/m/tick-white.png) no-repeat center 52% #999;
          background-size: 72%;
          width: 12px;
          height: 12px;
          border: 0;
          outline: 0;
          -webkit-appearance: none;
          -webkit-tap-highlight-color: transparent;
          font-size: inherit;
          color: inherit;
          border-radius: 100%;
          margin-right: 5px;
        }
        input:checked {
          background-color: #0065df;
          text-align: center;
          background-clip: padding-box;
        }
        p {
          margin-left: 5px;
        }
        span {
          color: #0065df;
        }
      }
      .weixin_login {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #f5f5f5;
        width: 100%;
        height: 54px;
        justify-content: center;
        border-radius: 0 0 5px 5px;
        p {
          font-size: 12px;
          color: #999999;
          margin-left: 9px;
        }
      }
    }
    // 微信登录
    .weixin_box {
      position: relative;
      height: 498px;

      border-radius: 5px 5px 5px 5px;
      .cont_text {
        margin-top: 34px;
        p.title_tips {
          font-size: 16px;
          color: #333333;
          text-align: center;
        }
        p.img_code {
          width: 240px;
          height: 240px;
          margin: 17px auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        div.deep_text {
          margin-top: 12px;
          text-align: center;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #999;
          span {
            cursor: pointer;
            color: #0065df;
          }
        }
        .btn_text {
          margin-top: 16px;
          text-align: center;
          justify-content: center;
          font-size: 14px;
          height: 54px;
          background: #f5f5f5;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-radius: 0 0 5px 5px;
          span {
            cursor: pointer;
            color: #0065df;
          }
          p {
            margin-left: 80px;
            color: #0065df;

            cursor: pointer;
          }
        }
      }
    }
    // 绑定手机号
    .get_code {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 17px 30px 0;
      // justify-content: center;
      p.tips_text {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
      ul {
        margin-top: 38px;
        li {
          margin-bottom: 22px;
          position: relative;
          height: 56px;
          input {
            width: 100%;
          }
          div.tips {
            position: absolute;
            left: 0;
            bottom: 0;
            color: #e15416;
          }
        }
      }
      input {
        width: 220px;
        height: 38px;
        padding-left: 11px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
      }

      p.btn {
        width: 140px;
        height: 38px;
        background: #0065df;
        border-radius: 4px 4px 4px 4px;
        margin-left: 32px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        font-size: 14px;
      }
      p.btnTime {
        background: #999;
      }
      div.deepBtn {
        width: 329px;
        height: 38px;
        background: #0065df;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 38px;
        text-align: center;
        margin-top: 8px;
      }
    }
    // 登录成功
    .login_success {
      display: flex;
      flex-direction: column;
      align-items: center;
      p.img {
        width: 100%;
        margin-top: 107px;
        background: url(@/assets/pc/login/box_bgm.png) no-repeat;
        background-position: 40px 0;

        img {
          display: block;
          width: 102px;
          height: 102px;
          margin: 0 auto;
        }
      }
      p.tips {
        font-size: 18px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-top: 39px;
      }
      h2 {
        font-size: 24px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 52px;
        text-align: center;
      }
      .tims {
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 95px;
      }
    }
  }
}
input::-webkit-input-placeholder {
  font-size: 14px;
}
input::-moz-input-placeholder {
  font-size: 14px;
}
input::-ms-input-placeholder {
  font-size: 14px;
}
</style>
